export default {
  getItemizedTotals(formName: string, values: Object) {
    let sum: number = 0;
    switch (formName) {
      case 'debt':
        for (const [key, value] of Object.entries(values)) {
          switch (key) {
            case 'debtAutoLoans':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'debtCreditCards':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'debtPersonalLoans':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'debtOther':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            default:
              sum += 0;
              break;
          }
        }
        return sum;

      case 'educationExpenses':
        for (const [key, value] of Object.entries(values)) {
          switch (key) {
            case 'child-1':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-2':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-3':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-4':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-5':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-6':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-7':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-8':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-9':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'child-10':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            default:
              sum += 0;
              break;
          }
        }
        return sum;
      case 'currentAssets':
        for (const [key, value] of Object.entries(values)) {
          switch (key) {
            case 'currentAssetsLifeInsurance':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'currentAssetsRetirement':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'currentAssetsSavingsChecking':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'currentAssetsStocksBonds':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'currentAssetsAnnuities':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            case 'currentAssetsOther':
              sum += isNaN(parseInt(value)) ? 0 : parseInt(value);
              break;
            default:
              sum += 0;
              break;
          }
        }
        return sum;
      default:
        break;
    }
  }
};
