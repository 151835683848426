// // stepper constants
// export const SET_STEPS = 'SET_STEPS';
// export const INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP';
// export const DECREMENT_CURRENT_STEP = 'DECREMENT_CURRENT_STEP';

export const STEP_NAMES = {
  GETTING_STARTED: 'Getting Started',
  MORTGAGE: 'mortgage',
  FINAL_EXPENSES: 'finalExpenses',
  DEBT: 'debt',
  EDUCATION_EXPENSES: 'educationExpenses',
  LIVING_EXPENSES: 'livingExpenses',
  CURRENT_ASSETS: 'currentAssets',
  CONTACT_REP: 'contactRep',
  THANKYOU: 'thankYou'
};

export const STEP_LABELS = {
  GETTING_STARTED: 'Getting Started',
  MORTGAGE: 'Mortgage',
  FINAL_EXPENSES: 'Final Expenses',
  DEBT: 'Debt',
  EDUCATION_EXPENSES: 'Education Expenses',
  LIVING_EXPENSES: 'Living Expenses',
  CURRENT_ASSETS: 'Current Assets',
  CONTACT_REP: 'Contact A Representative',
  THANKYOU: 'Thank You'
};
