import { FormikErrors } from 'formik';

import {
  isAvailableZipCode,
  isValidEmail,
  isValidPhoneNumber,
  isValidZipCode
} from '../../utils/validation.utils';
import { MasterFormValues } from './Interface/types';
import utils from './utils/utils';

const LICalcValidator = async (
  values: MasterFormValues
): Promise<FormikErrors<MasterFormValues>> => {
  const errors: FormikErrors<MasterFormValues> = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 50) {
    errors.firstName = 'Please enter no more than 50 characters';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 50) {
    errors.lastName = 'Please enter no more than 50 characters';
  }

  if (!values.age) {
    errors.age = 'Required';
  } else if (!utils.isNumeric(values.age)) {
    errors.age = 'Please provide a valid age';
    errors.gettingStarted = 'Please enter no more than 50 characters';
  }

  if (values.debt) {
    if (!utils.isNumeric(values.debt)) {
      errors.debt = 'Please provide a valid number for debt';
    }
  }

  if (values.mortgage) {
    if (!utils.isNumeric(values.mortgage)) {
      errors.mortgage = 'Please provide a valid number for mortgage';
      errors.gettingStarted = 'Please enter no more than 50 characters';
    }
  }

  if (values.monthlyLivingExpenses) {
    if (!utils.isNumeric(values.monthlyLivingExpenses)) {
      errors.monthlyLivingExpenses =
        'Please provide a valid number for monthly living expenses';
      errors.livingExpenses =
        'Please provide a valid number for monthly living expenses';
    }
  }

  if (values.yearsNeeded) {
    if (!utils.isNumeric(values.yearsNeeded)) {
      errors.yearsNeeded = 'Please provide a valid number for years needed';
      errors.livingExpenses = 'Please provide a valid number for years needed';
    }
  }

  if (values.currentAssets) {
    if (!utils.isNumeric(values.currentAssets)) {
      errors.currentAssets = 'Please provide a valid number for current assets';
    }
  }

  if (values.educationExpenses) {
    if (!utils.isNumeric(values.educationExpenses)) {
      errors.educationExpenses =
        'Please provide a valid number for education expenses';
    }
  }

  if (values.debtAutoLoans) {
    if (!utils.isNumeric(values.debtAutoLoans)) {
      errors.debtAutoLoans = 'Please provide a valid amount.';
    }
  }

  if (values.debtCreditCards) {
    if (!utils.isNumeric(values.debtCreditCards)) {
      errors.debtCreditCards = 'Please provide a valid amount.';
    }
  }

  if (values.debtPersonalLoans) {
    if (!utils.isNumeric(values.debtPersonalLoans)) {
      errors.debtPersonalLoans = 'Please provide a valid amount.';
    }
  }

  if (values.debtOther) {
    if (!utils.isNumeric(values.debtOther)) {
      errors.debtOther = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsLifeInsurance) {
    if (!utils.isNumeric(values.currentAssetsLifeInsurance)) {
      errors.currentAssetsLifeInsurance = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsRetirement) {
    if (!utils.isNumeric(values.currentAssetsRetirement)) {
      errors.currentAssetsRetirement = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsSavingsChecking) {
    if (!utils.isNumeric(values.currentAssetsSavingsChecking)) {
      errors.currentAssetsSavingsChecking = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsStocksBonds) {
    if (!utils.isNumeric(values.currentAssetsStocksBonds)) {
      errors.currentAssetsStocksBonds = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsAnnuities) {
    if (!utils.isNumeric(values.currentAssetsAnnuities)) {
      errors.currentAssetsAnnuities = 'Please provide a valid amount.';
    }
  }
  if (values.currentAssetsOther) {
    if (!utils.isNumeric(values.currentAssetsOther)) {
      errors.currentAssetsOther = 'Please provide a valid amount.';
    }
  }

  if (values['child-1']) {
    if (!utils.isNumeric(values['child-1'])) {
      errors['child-1'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-2']) {
    if (!utils.isNumeric(values['child-2'])) {
      errors['child-2'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-3']) {
    if (!utils.isNumeric(values['child-3'])) {
      errors['child-3'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-4']) {
    if (!utils.isNumeric(values['child-4'])) {
      errors['child-4'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-5']) {
    if (!utils.isNumeric(values['child-5'])) {
      errors['child-5'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-6']) {
    if (!utils.isNumeric(values['child-6'])) {
      errors['child-6'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-7']) {
    if (!utils.isNumeric(values['child-7'])) {
      errors['child-7'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-8']) {
    if (!utils.isNumeric(values['child-8'])) {
      errors['child-8'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-9']) {
    if (!utils.isNumeric(values['child-9'])) {
      errors['child-9'] = 'Please provide a valid amount.';
    }
  }
  if (values['child-10']) {
    if (!utils.isNumeric(values['child-10'])) {
      errors['child-10'] = 'Please provide a valid amount.';
    }
  }

  if (!values.zipCode) {
    errors.zipCode = 'Required';
  } else if (!isValidZipCode(values.zipCode)) {
    errors.zipCode = 'Please provide a valid 5-digit zip code';
  } else if ((await isAvailableZipCode(values.zipCode)) === false) {
    errors.zipCode =
      'Life insurance and annuity products are currently not available in your state. If you are a current member in need of assistance, please contact our Customer Service team at 833-WOODMEN (833-966-3636).';
  }

  if (!values.gender) {
    errors.gender = 'Please provide gender.';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!isValidPhoneNumber(values.phone)) {
    errors.phone = 'Please provide a valid phone number';
  }

  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Please provide a valid email address';
  } else if (values.email.length > 150) {
    errors.email = 'Please enter no more than 150 characters';
  }

  return errors;
};

export default LICalcValidator;
