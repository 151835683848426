export const campaignImagesState = {
  default: 'o1465',
  active: ['o1458', 'o1459', 'o1460', 'o1461', 'o1462', 'o1465'],
  attr: [
    {
      id: 'o1458',
      alt: 'Father holding baby son at kitchen table with mother and daughter nearby.'
    },
    {
      id: 'o1459',
      alt: 'Mother standing beside parked car while holding baby son as he reaches for an item from a grocery bag.'
    },
    {
      id: 'o1460',
      alt: 'Mother and father reading a story to their two infant children while together in bed.'
    },
    {
      id: 'o1461',
      alt: 'Middle-aged couple seated together on a sofa and holding a tablet.'
    },
    {
      id: 'o1462',
      alt: 'Young couple standing together enjoying breakfast with their infant son who is seated on the counter.'
    },
    {
      id: 'o1465',
      alt: 'Young couple standing together enjoying breakfast with their infant son who is seated on the counter.'
    }
  ]
};
