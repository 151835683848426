// extracted by mini-css-extract-plugin
export var Ad = "Ad-module--Ad--50c70";
export var AdHeader = "Ad-module--AdHeader--33ff3";
export var AdHeading = "Ad-module--AdHeading--32ca4";
export var AdBanner = "Ad-module--AdBanner--a1627";
export var AdBannerCircle = "Ad-module--AdBannerCircle--2d131";
export var Lines = "Ad-module--Lines--ddbe4";
export var AdBannerCircleText = "Ad-module--AdBannerCircleText--8cbd9";
export var SuperScript = "Ad-module--SuperScript--79945";
export var BigText = "Ad-module--BigText--f9650";
export var AdBannerText = "Ad-module--AdBannerText--d3354";
export var AdBody = "Ad-module--AdBody--a1f50";
export var AdImage = "Ad-module--AdImage--774ff";
export var O1465 = "Ad-module--O1465--c2e2a";
export var AdFooter = "Ad-module--AdFooter--c9d61";