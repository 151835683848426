import { Image } from '@chakra-ui/react';
import React from 'react';

import { FindProducerResponse } from '../../../../apis';
import MissingHeadshot from '../../../../images/missing-headshot-repcard.png';
import {
  getRepresentativeName,
  getRepresentativePhone,
  getRepresentativeTitle,
} from '../../../../utils/representative.utils';
import * as Styles from '../RepCard/RepCard.module.scss';

interface RepDetailsParams {
  producer: FindProducerResponse;
}

const RepCard = ({ producer }: RepDetailsParams) => {
  const producerTitle = getRepresentativeTitle(producer);
  const producerName = getRepresentativeName(producer);
  let producerPhone = producer.phone;

  if (producer.phone) producerPhone = getRepresentativePhone(producer.phone);

  const image = React.useMemo(
    () =>
      producer.image ? (
        <Image src={producer.image} alt={`Image of ${producerName}`} />
      ) : (
        <Image
          src={MissingHeadshot as string}
          alt={`Image of ${producerName}`}
        />
      ),
    [producer.image, producerName],
  );

  return (
    <>
      <div className={Styles.repCard}>
        <div className={Styles.repHelp}>
          <div className={Styles.repIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="20"
              viewBox="0 0 9 20"
            >
              <text
                id="_"
                data-name="?"
                transform="translate(0 16)"
                fill="#fff"
                font-size="16"
                font-family="Inter"
                font-weight="700"
              >
                <tspan x="0" y="0">
                  ?
                </tspan>
              </text>
            </svg>
          </div>
          <div className={Styles.repMessage}>
            <p>Need some help?</p>
          </div>
        </div>

        <div
          className={Styles.repAvatar}
          style={{
            border: producer.image ? 'none' : '.5px solid #DBD9D7',
            background: producer.image ? 'none' : 'white',
          }}
        >
          {image && image}
        </div>
        <div className={Styles.repInfo}>
          <p className={Styles.repPosition}>Your Sales Representative</p>
          <p className={Styles.repName}>{producerName}</p>
          {producerPhone && (
            <div className={Styles.repPhone}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="16"
                viewBox="0 0 14 16"
              >
                <g
                  id="Group_392"
                  data-name="Group 392"
                  transform="translate(-14114 -546)"
                >
                  <rect
                    id="Rectangle_819"
                    data-name="Rectangle 819"
                    width="14"
                    height="16"
                    transform="translate(14114 546)"
                    fill="rgba(255,255,255,0)"
                  />
                  <path
                    id="Phone_Icon"
                    data-name="Phone Icon"
                    d="M237.765,335.294l-.545,2.363a.728.728,0,0,1-.714.568A10.735,10.735,0,0,1,225.784,327.5a.728.728,0,0,1,.568-.713l2.362-.545a.734.734,0,0,1,.838.424l1.091,2.543a.735.735,0,0,1-.211.855l-1.262,1.013a8.2,8.2,0,0,0,3.739,3.738l1.033-1.261a.729.729,0,0,1,.854-.21l2.543,1.09A.8.8,0,0,1,237.765,335.294Z"
                    transform="translate(13889.216 222.776)"
                    fill="#00549f"
                  />
                </g>
              </svg>
              <p>
                <a href={`tel:${producerPhone}`}>{producerPhone}</a>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RepCard;
