// extracted by mini-css-extract-plugin
export var CardShadow = "MasterForm-module--CardShadow--fa5ee";
export var LeftColumnGray = "MasterForm-module--LeftColumnGray--b9cdd";
export var LeftColumn = "MasterForm-module--LeftColumn--c5758";
export var LeftColumnHeader = "MasterForm-module--LeftColumnHeader--7be42";
export var HeadlineDivider = "MasterForm-module--HeadlineDivider--52045";
export var HeadlineDividerItem = "MasterForm-module--HeadlineDividerItem--3ab43";
export var HeadlineDividerItemLine = "MasterForm-module--HeadlineDividerItemLine--cea6f";
export var HeadlineDividerItemText = "MasterForm-module--HeadlineDividerItemText--60ff1";
export var RightColumnHasBorder = "MasterForm-module--RightColumnHasBorder--471c3";
export var RightColumn = "MasterForm-module--RightColumn--0f3c0";
export var MasterForm = "MasterForm-module--MasterForm--ac0cf";
export var RightColumnContent = "MasterForm-module--RightColumnContent--560af";
export var StepHeading = "MasterForm-module--StepHeading--45533";
export var StepDescription = "MasterForm-module--StepDescription--dddc4";
export var InputWrapper = "MasterForm-module--InputWrapper--b9d05";
export var ButtonContainer = "MasterForm-module--ButtonContainer--0e4e8";
export var ButtonContainerFlex = "MasterForm-module--ButtonContainerFlex--1870a";
export var ButtonBlue = "MasterForm-module--ButtonBlue--54a39";
export var ButtonTransparent = "MasterForm-module--ButtonTransparent--01d49";
export var ButtonRed = "MasterForm-module--ButtonRed--d46a1";