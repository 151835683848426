import React from 'react';

import * as Styles from './CalculateButton.module.scss';

export default function CalculateButton(props: any) {
  const { onClick, type } = props;
  return (
    <button
      className={Styles.CalculateButton}
      type={type || 'button'}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
}
