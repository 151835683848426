import * as React from 'react';

import * as Styles from './CurrencyPrefix.module.scss';

export default function CurrencyPrefix(props: any) {
  return (
    <span style={props.style} className={Styles.CurrencyPrefix}>
      $
    </span>
  );
}
