import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLInput } from '../../../../../design-library';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import utils from '../../../utils/utils';
import CalculateButton from '../../CalculateButton/CalculateButton';
import CurrencyPrefix from '../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../InputContainer/InputContainer';
export default function Debt(props: any) {
  const { onChange, handleFlyout, onBlur, onFocus } = props;
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.DEBT, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.DEBT, mixpanel);
    };
  }, []);

  const callHandleFlyout = () => {
    handleFlyout(true, 'Debt');
  };

  return (
    <>
      <h2 className={Globals.StepHeading}>Debt</h2>
      <p className={Globals.Paragraph}>
        When you pass away, your family will need to pay any debts, including
        credit cards and loans.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ minHeight: '90px' }}>
          <FormikFieldAdapter
            name="debt"
            renderField={(adapterProps) => (
              <>
                <InputContainer style={{ marginBottom: '0' }}>
                  <CurrencyPrefix />
                  <WLInput
                    {...adapterProps}
                    variant={SelectVariant.FLUSHED}
                    autoFocus={false}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e, STEP_LABELS.DEBT)}
                    onFocus={(e) => onFocus(e, STEP_LABELS.DEBT)}
                    paddingLeft={'60px'}
                  />
                </InputContainer>
              </>
            )}
          />
        </div>
        <div className={Globals.FlexColumn}>
          <CalculateButton type="button" onClick={callHandleFlyout}>
            Help me calculate
          </CalculateButton>
        </div>
      </div>
    </>
  );
}
