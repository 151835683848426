import React from 'react';

import * as Globals from '../../../../global.module.scss';
import * as Styles from './EducationExpensesChart.module.scss';

export default function EducationExpensesChart(props: any) {
  return (
    <div className={Styles.ComparisonChartTable}>
      <table
        className={`${Styles.Table} ${Styles.TableFullWidth}`}
        role="table"
      >
        <tbody>
          <tr className={Styles.TRowHeight}>
            <th className={Styles.TRowHeight} colSpan={2}>
              National Average of College Tuition and Fees for One Year
            </th>
          </tr>
          <tr className={Styles.TRowHeight}>
            <td className={Styles.TDStyle} role="rowheader" scope="row">
              Public Two-Year In-State
            </td>
            <td className={Styles.TDHeight}>$3,770</td>
          </tr>
          <tr className={Styles.TRowHeight}>
            <td className={Styles.TDStyle} role="rowheader" scope="row">
              Public Four-Year In-State
            </td>
            <td className={Styles.TDHeight}>$10,560</td>
          </tr>
          <tr className={Styles.TRowHeight}>
            <td className={Styles.TDStyle} role="rowheader" scope="row">
              Public Four-Year Out-of-State
            </td>
            <td className={Styles.TDHeight}>$27,020</td>
          </tr>
          <tr className={Styles.TRowHeight}>
            <td className={Styles.TDStyle} role="rowheader" scope="row">
              Private Four-Year
            </td>
            <td className={Styles.TDHeight}>$37,650</td>
          </tr>
        </tbody>
      </table>
      <p className={`${Globals.Paragraph} ${Globals.ParagraphSmall}`}>
        Source: Trends in College Pricing 2020, New York: College Board. 2020
      </p>
    </div>
  );
}
