import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLInput } from '../../../../../design-library';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import utils from '../../../utils/utils';
import CalculateButton from '../../CalculateButton/CalculateButton';
import CurrencyPrefix from '../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../InputContainer/InputContainer';

export default function EducationExpenses(props: any) {
  const mixpanel = useMixpanel();
  const { onChange, handleFlyout, onBlur, onFocus } = props;

  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.EDUCATION_EXPENSES, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.EDUCATION_EXPENSES, mixpanel);
    };
  }, []);

  const callHandleFlyout = () => {
    handleFlyout(true, 'Education Expenses');
  };
  return (
    <>
      <h2 className={Globals.StepHeading}>Education Expenses</h2>
      <p className={Globals.Paragraph}>
        When you pass away, life insurance could help your family members pay
        for college, university or trade school.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ minHeight: '90px' }}>
          <FormikFieldAdapter
            name="educationExpenses"
            renderField={(adapterProps) => (
              <>
                <InputContainer style={{ marginBottom: '0' }}>
                  <CurrencyPrefix />
                  <WLInput
                    {...adapterProps}
                    variant={SelectVariant.FLUSHED}
                    autoFocus={false}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e, STEP_LABELS.EDUCATION_EXPENSES)}
                    onFocus={(e) => onFocus(e, STEP_LABELS.EDUCATION_EXPENSES)}
                  />
                </InputContainer>
              </>
            )}
          />
        </div>
        <div className={Globals.FlexColumn}>
          <CalculateButton type="button" onClick={callHandleFlyout}>
            Help me calculate
          </CalculateButton>
        </div>
      </div>
    </>
  );
}
