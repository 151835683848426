import React from 'react';

import * as Globals from '../../global.module.scss';
import * as Styles from './Footer.module.scss';

export default function Footer(props: any) {
  return (
    <div className={`${Styles.Footer} ${Globals.FlexDirectionColumn}`}>
      <div className={Globals.LeftColumn}>
        {!props.repData && (
          <p
            className={`${Globals.ParagraphNarrow} ${Globals.PaddingLeftTabletWide} ${Globals.PaddingRightTabletWide}`}
          >
            Have questions?{' '}
            <a
              className={`${Globals.TextLink} ${Globals.FontWeightBold}`}
              href="/find/rep/"
              target="_blank"
            >
              Connect with a Rep.
            </a>
          </p>
        )}
      </div>
      <div className={Globals.RightColumn}>
        <section
          id="bottomDisclosures"
          className={`${Globals.PaddingLeftTabletWide} ${Globals.PaddingRightTabletWide}`}
        >
          <h4
            className={`${Globals.FontWeightBold} ${Globals.TextSmall} ${Globals.TextGray70}`}
          >
            Disclosures
          </h4>
          <p className={`${Globals.TextSmall} ${Globals.TextGray50}`}>
            <sup>*</sup>Rate based on 10-year term life insurance, 35-year-old
            Female, preferred status, nonsmoker.
          </p>
          <strong className={`${Globals.TextSmall} ${Globals.TextGray50}`}>
            All products may not be available in all states.
          </strong>
          <p className={`${Globals.TextSmall} ${Globals.TextGray50}`}>
            Form ICC16 8648 1-16 &amp; Form 8648 1-16 (XX). Premiums are
            applicable during the 10-Year Level Term period only. Premiums are
            based on monthly PAC premium payments. Qualification for the premium
            shown is dependent upon underwriting and approval by WoodmenLife.
            Premiums for 10, 15, 20 or 30-year term are guaranteed not to
            increase during the initial policy term. Once the initial policy
            term ends, your premiums will increase on an annual basis until age
            95. Any increase in premium will not increase the certificate
            benefits. These certificates have exceptions, limitations and
            restrictions. For cost and complete details in coverage, contact
            your WoodmenLife representative. Products are not available in New
            York and all products may not be available in all states.
          </p>
          <p className={`${Globals.TextSmall} ${Globals.TextGray50}`}>
            This is an estimate only and not an offer of insurance coverage.
            Please consult with your insurance professional to determine the
            amount of life insurance protection that may be appropriate for you.
            Information and interactive calculators are hypothetical and for
            illustrative use only. This analysis is based solely on the
            information you provide. This content is for general educational
            purposes only. It is not intended to provide fiduciary, tax, or
            legal advice.
          </p>
          <p
            className={`${Globals.TextSmall} ${Globals.FontWeightBold} ${Globals.TextGray50}`}
          >
            Web 30 R-3/23
          </p>
        </section>
      </div>
    </div>
  );
}
