import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLInput } from '../../../../../design-library';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import CalculateButton from '../../CalculateButton/CalculateButton';
import CurrencyPrefix from '../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../InputContainer/InputContainer';

export default function CurrentAssets(props: any) {
  const mixpanel = useMixpanel();
  const { onChange, onBlur, onFocus, handleFlyout } = props;
  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.CURRENT_ASSETS, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.CURRENT_ASSETS, mixpanel);
    };
  }, []);

  const callHandleFlyout = () => {
    handleFlyout(true, 'Current Assets');
  };

  return (
    <>
      <h2 className={Globals.StepHeading}>Current Assets</h2>
      <p className={Globals.Paragraph}>
        Money you've saved or invested can also help pay for expenses.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ height: '90px' }}>
          <FormikFieldAdapter
            name="currentAssets"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onChange={(e) => onChange(e)}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn}>
          <CalculateButton type="button" onClick={callHandleFlyout}>
            Help me calculate
          </CalculateButton>
        </div>
      </div>
    </>
  );
}
