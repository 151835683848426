import { Box, Center } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { STEP_NAMES } from '../../store/constants/stepConstants';
import stepperUtils from '../../utils/stepperUtils';
import utils from '../../utils/utils';
import RepCard from '../RepCard/RepCard';
import StepMarker from '../StepMarker/StepMarker';
import * as Styles from '../Stepper/Stepper.module.scss';

interface StepperProps {
  activeStep: number;
  totalSteps: number;
  totalNeed: number | string;
  errors: {
    [index: string]: string;
  };
  repData?: Object | undefined;
  onClick: (e: any, idx: number) => void;
  steps: [
    {
      name: string;
      label: string;
      heading: string;
      value: string;
      active: boolean;
      enabled: boolean;
      visible: boolean;
      visited: boolean;
      valid: boolean;
      hasLine: boolean;
      showValue: boolean;
      details: boolean;
      doNotList?: boolean;
      fields?: [
        {
          name: string;
          label: string;
          valid: string;
          required: string;
          format: string;
          type: string;
          value: string;
          doNotList: string;
        },
      ];
    },
  ];
}

export default function Stepper(props: StepperProps) {
  const { activeStep, totalSteps, totalNeed, steps, onClick, errors } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showGlass, setShowGlass] = useState(false);
  const [toggleSummary, setToggleSummary] = useState(false);
  const [stepMarkerValid, setStepMarkerValid] = useState(true);
  const [stepActive, setStepActive] = useState();
  const [allowRootScrolling, setAllowRootScrolling] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // add is desktop state
  const handleRootScrolling = (scroll: boolean) => {
    let root = document.getElementsByTagName('html')[0];
    if (document && !scroll) {
      root.style.overflow = 'hidden';
    } else if (document && scroll) {
      root.style.overflow = 'visible';
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  useEffect(() => {
    if (isMobile && isExpanded && showGlass) {
      handleRootScrolling(false);
    } else {
      handleRootScrolling(true);
    }
  }, [toggleSummary, showGlass, isMobile]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const summaryTotal = (totalNeed: string | number) => {
    if (utils.isEmpty(totalNeed)) {
      totalNeed = 0;
    }
    return !utils.isEmpty(totalNeed) ? utils.numFormatUSDRound(totalNeed) : '';
  };

  const handleToggleSummary = () => {
    setToggleSummary((prevState) => !prevState);
    setShowGlass((prevState) => !prevState);
  };

  const handleSetIsExpanded = () => {
    setIsExpanded((prevState) => !prevState);
    setShowGlass((prevState) => !prevState);
    if (isExpanded && showGlass) {
      handleRootScrolling(true);
    }
  };

  const handleStepClick = (e: any, idx: number) => {
    handleSetIsExpanded();
    e.stopPropagation();
    // callback to parent to set active step
    // and disable flyout
    onClick(e, idx);
    handleToggleSummary();
  };

  return (
    <Box>
      <Box
        className={`${Styles.Stepper} ${isExpanded && Styles.StepperExpanded}`}
      >
        <header className={Styles.StepperHeader}>
          <a className={Styles.HitArea} onClick={handleSetIsExpanded}></a>

          <h2
            className={`${Styles.Heading} ${
              activeStep === 0 ||
              (activeStep >= stepperUtils.visibibleSteps(steps).length &&
                Styles.HeadingHidden)
            }`}
          >
            {props.repData && (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: '1',
                  width: '22px',
                  height: '22px',
                  borderRadius: '22px',
                  background: !isExpanded ? '#003366' : '#DBD9D7',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="20"
                  viewBox="0 0 9 20"
                >
                  <text
                    id="_"
                    data-name="?"
                    transform="translate(0 16)"
                    fill="#fff"
                    font-size="16"
                    font-family="Inter"
                    font-weight="700"
                  >
                    <tspan x="0" y="0">
                      ?
                    </tspan>
                  </text>
                </svg>
              </div>
            )}
            <Center>
              {activeStep + 1 <= stepperUtils.visibibleSteps(steps).length
                ? `Step ${activeStep + 1} of ${
                    stepperUtils.visibibleSteps(steps).length
                  }`
                : `Step 0 of 4`}
            </Center>
          </h2>
          <svg
            className={Styles.CardToggleArrow}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 48"
          >
            <title>Arrow</title>
            <path
              d="M2.28,47.52A2.15,2.15,0,0,1,1,43.63L28.45,22.47A2.15,2.15,0,0,1,31,25.94L3.55,47.1A2.13,2.13,0,0,1,2.28,47.52Z"
              style={{ fill: '#212121' }}
            ></path>
            <path
              d="M29.72,26.15a2.15,2.15,0,0,1-1.32-.45L1,4.33A2.15,2.15,0,0,1,3.6.93L31,22.3a2.15,2.15,0,0,1-1.32,3.85Z"
              style={{ fill: '#212121' }}
            ></path>
          </svg>
        </header>
        <Box className={Styles.Steps}>
          <Center>
            {props.repData && <RepCard producer={props.repData} />}
          </Center>
          {steps.map((step, idx) => {
            let errorsExist = false;
            if (step.name === STEP_NAMES.GETTING_STARTED) {
              step.fields!.forEach((field, idx) => {
                if (errors[field.name]) {
                  errorsExist = true;
                }
              });
            }
            return (
              !step.doNotList && (
                <Box
                  key={idx}
                  className={`${Styles.Step} ${
                    step.visited && Styles.StepVisited
                  }`}
                >
                  <a
                    className={`${Styles.StepLink} ${
                      step.active && Styles.StepLinkActive
                    }`}
                    onClick={(e) => handleStepClick(e, idx)}
                  >
                    <Box className={Styles.FlexRow}>
                      <Box className={Styles.StepMarkerContainer}>
                        {step.visited && (
                          <StepMarker
                            valid={
                              !errors[step.name] && !errorsExist ? true : false
                            }
                          />
                        )}
                      </Box>
                      <Box className={Styles.StepLabel}>
                        <Box className={Styles.TextContainer}>
                          <Box className={Styles.StepIdentifier}>
                            Step {idx + 1}
                          </Box>
                          {step.label}
                        </Box>
                      </Box>
                      <Box
                        className={`${Styles.FlexColumn} ${
                          step.hasLine && Styles.FlexColumnLined
                        }`}
                      ></Box>
                      {step.showValue && (
                        <Box className={Styles.StepValue}>
                          <Box className={Styles.TextContainer}>
                            {parseInt(step.value) !== 0
                              ? stepperUtils.formatValue(step)
                              : ''}
                          </Box>
                        </Box>
                      )}
                      <Box className={Styles.StepIconContainer}>
                        <svg className={`${Styles.Icon}`} viewBox="0 0 16 16">
                          <rect
                            x="5"
                            y="3.11"
                            width="4.84"
                            height="10.97"
                            transform="translate(8.25 -2.73) rotate(45)"
                          />
                          <path d="M2.09,11.75l2.17,2.18-3,.85.85-3m-.26-1L.49,15.53l4.76-1.34L1.83,10.76Z" />
                          <path
                            d="M11.3,1.11h3.58a.63.63,0,0,1,.63.63v3a0,0,0,0,1,0,0H10.66a0,0,0,0,1,0,0v-3a.63.63,0,0,1,.63-.63Z"
                            transform="translate(5.9 -8.4) rotate(45)"
                          />
                        </svg>
                      </Box>
                    </Box>
                    {!step.doNotList &&
                      step.details &&
                      step.fields &&
                      step.fields.map((field) => {
                        return (
                          !(
                            (field.name === 'numberOfChildren' &&
                              field.value === '0') ||
                            utils.isEmpty(field.value)
                          ) && (
                            <>
                              <Box
                                key={`${idx}-${field.name}`}
                                className={Styles.StepDetails}
                              >
                                <Box className={Styles.FlexRow}>
                                  <Box className={Styles.StepLabel}>
                                    {field.label}
                                  </Box>
                                  <Box
                                    className={`${Styles.FlexColumn} ${Styles.FlexColumnLined}`}
                                  ></Box>
                                  <Box className={Styles.StepValue}>
                                    {stepperUtils.formatValue(field)}
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          )
                        );
                      })}
                  </a>
                </Box>
              )
            );
          })}
        </Box>
        <footer className={Styles.StepperFooter}>
          <Box className={Styles.StepperTotal}>
            <Box className={Styles.StepperTotalLabel}>Total Need</Box>
            <Box className={Styles.StepperTotalValue}>
              {summaryTotal(totalNeed)}
            </Box>
          </Box>
        </footer>
      </Box>
      {/* transition for glass / glass summary */}
      {isExpanded && (
        <Box
          className={`${Styles.Glass} ${Styles.GlassSummary}`}
          onClick={handleSetIsExpanded}
        />
      )}
    </Box>
  );
}
