import React from 'react';

import * as Styles from './Header.module.scss';

export default function Header(props: any) {
  return (
    <div>
      <div className={Styles.RightColumnHeader}>
        <h2 className={Styles.RightColumnHeaderText}>
          How Much Life Insurance Do I Need?
        </h2>
      </div>
    </div>
  );
}
