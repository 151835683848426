import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { StatesByAbbreviation } from '../../../../../constants/states';
import { WLInput, WLSelect } from '../../../../../design-library';
import { HelperTextPosition } from '../../../../../design-library/Forms/FormField/FormField';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import { useRepSearch } from '../../../../../hooks/useRepSearch';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { NumberOfChildren } from '../../../store/constants/formFieldConstants';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import RepCard from '../../RepCard/RepCard';
import * as Styles from './GettingStarted.module.scss';

export default function StepOne(props: any) {
  const { onChange, onBlur, onFocus } = props;
  const mixpanel = useMixpanel();
  const [repLookupComplete, repData] = useRepSearch();
  const stateOptions = React.useMemo(
    () =>
      Object.entries(StatesByAbbreviation).map(([abbrev, name]) => ({
        label: `${name} (${abbrev})`,
        value: abbrev,
      })),
    [],
  );

  const numberOfChildrenOptions = React.useMemo(
    () =>
      Object.entries(NumberOfChildren).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [],
  );

  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.GETTING_STARTED, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.GETTING_STARTED, mixpanel);
    };
  }, []);

  return (
    <>
      <h2 className={Styles.StepHeadline}>
        Let's get started
        <br />
        on your customized estimate.
      </h2>
      {repData && repData.producerId && <RepCard producer={repData} />}
      <div
        className={`${Globals.FlexRow} ${Globals.JustifyContentSpaceBetween} ${Globals.FlexWrap}`}
      >
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="age"
            label="Age"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
                onFocus={(e) => onFocus(e, STEP_LABELS.GETTING_STARTED)}
              />
            )}
          />
        </div>
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="gender"
            label="Gender"
            required
            renderField={(adapterProps) => (
              <>
                <WLSelect
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  options={[
                    { label: '', value: 'Unknown' },
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' },
                  ]}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
                />
              </>
            )}
          />
        </div>
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="maritalStatus"
            label="Marital Status"
            required
            renderField={(adapterProps) => (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                options={[
                  { label: '', value: 'Unknown' },
                  { label: 'Married', value: 'Married' },
                  { label: 'Single', value: 'Single' },
                  { label: 'Living with a partner', value: 'Partner' },
                ]}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
              />
            )}
          />
        </div>
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="numberOfChildren"
            label="Number of Children"
            renderField={(adapterProps) => (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                options={numberOfChildrenOptions}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
              />
            )}
          />
        </div>
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="livingSituation"
            label="Home Ownership"
            required
            renderField={(adapterProps) => (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                options={[
                  { label: '', value: 'Unknown' },
                  { label: 'Rent', value: 'Rent' },
                  { label: 'Own', value: 'Own' },
                ]}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
              />
            )}
          />
        </div>
        <div className={Globals.FieldInTwoColumns}>
          <FormikFieldAdapter
            name="state"
            label="State"
            required
            helperText="Not available in all states"
            helperTextPosition={HelperTextPosition.bottom}
            renderField={(adapterProps) => (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                options={stateOptions}
                placeholder="Choose a state..."
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.GETTING_STARTED)}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
