import React from 'react';

import { WLButton, WLInput } from '../../../../../../design-library';
import { SelectVariant } from '../../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../../Form/FormikFieldAdapter';

export default function FinalExpenseItemizeForm(props: any) {
  return (
    <div>
      <h2 className="Subheading u-asParagraph--Large">
        Help Me Calculate My Total Final Expenses
      </h2>
      <p className="Para">
        Life insurance can be used to pay for funeral services, but also items
        like medical bills and legal fees.
      </p>
      <div className="FlexRow FlexRow--050505 ColumnGap8--noOuterPadding">
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesFuneralExpenses"
            label="Funeral Expenses"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesHospital"
            label="Hospital"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
      </div>
      <div className="FlexRow FlexRow--050505 ColumnGap8--noOuterPadding">
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesLegalFees"
            label="Legal Fees"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesDoctorBills"
            label="Doctor Bills"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
      </div>
      <div className="FlexRow FlexRow--050505 ColumnGap8--noOuterPadding">
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesTaxesDue"
            label="Taxes Due"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
        <div className="FlexColumn">
          <FormikFieldAdapter
            name="finalExpensesOther"
            label="Other"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
              />
            )}
          />
        </div>
      </div>
      <div className="FlexRow FlexRow--050505 ColumnGap8--noOuterPadding">
        <div className="FlexColumn">
          <div id="finalExpenseTotalDiv" className="Subtotal m-b-24">
            <strong>Total: $</strong>
            {/*function for updating total expenses*/}
          </div>
        </div>
      </div>
      <div className="ButtonContainer ButtonContainer--flex">
        <WLButton type="button" colorScheme="blue" aria-label="Update">
          Update
        </WLButton>
      </div>
    </div>
  );
}
