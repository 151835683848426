import { Box } from '@chakra-ui/react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLH2, WLInput, WLText } from '../../../../../design-library';
import { HelperTextPosition } from '../../../../../design-library/Forms/FormField/FormField';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import { PhoneInputField } from '../../../../Form';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import utils from '../../../utils/utils';
import * as Styles from './ContactRep.module.scss';
export default function ContactRep(props: any) {
  const mixpanel = useMixpanel();
  const { onChange, totalIdx, steps, onBlur, onFocus, errors } = props;
  let total = steps[totalIdx].total;

  React.useEffect(() => {
    const ts = Math.floor(Date.now() / 1000);
    trackStepStartMixpanel(STEP_LABELS.CONTACT_REP, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.CONTACT_REP, mixpanel);
    };
  }, []);

  return (
    <>
      <p className={Globals.Paragraph} style={{ textAlign: 'center' }}>
        Based on your answers, your estimated life insurance need is
        approximately
      </p>
      <Box
        className={Styles.Total}
        style={{ marginBottom: '24px', textAlign: 'center' }}
      >
        <span className={`${Styles.TotalText} ${Styles.TotalTextSuperscript}`}>
          $
        </span>
        <span className={Styles.TotalText}>
          {total ? utils.numFormat(total) : 0}
        </span>
        <span className={`${Styles.TotalText} ${Styles.TotalTextSuperscript}`}>
          .00
        </span>
      </Box>
      <WLH2 className={`${Globals.Subheading} ${Globals.ParagraphLarge}`}>
        Let's Talk
      </WLH2>
      <WLText className={Globals.Paragraph}>
        {props.repData && props.repData.alias
          ? `${props.repData.alias} `
          : props.repData
          ? `${props.repData.firstName} `
          : `Your local Representative `}
        will call to give you details on how WoodmenLife can help your family.
        To protect your personal information, we do not offer quotes online. We
        are able to serve you via video call.
      </WLText>
      <Box
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}>
          <FormikFieldAdapter
            name="firstName"
            label="First Name"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.CONTACT_REP)}
                onFocus={(e) => onFocus(e, STEP_LABELS.CONTACT_REP)}
              />
            )}
          />
        </Box>
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}>
          <FormikFieldAdapter
            name="lastName"
            label="Last Name"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.CONTACT_REP)}
                onFocus={(e) => onFocus(e, STEP_LABELS.CONTACT_REP)}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}>
          <FormikFieldAdapter
            name="phone"
            label="Phone Number"
            required
            helperText="###-###-#### "
            helperTextPosition={HelperTextPosition.bottom}
            renderField={(adapterProps) => (
              <PhoneInputField
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.CONTACT_REP)}
                onFocus={(e) => onFocus(e, STEP_LABELS.CONTACT_REP)}
              />
            )}
          />
        </Box>
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}>
          <FormikFieldAdapter
            name="zipCode"
            label="ZIP Code"
            required
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.CONTACT_REP)}
                onFocus={(e) => onFocus(e, STEP_LABELS.CONTACT_REP)}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}>
          <FormikFieldAdapter
            name="email"
            label="Email Address"
            helperText="Optional"
            helperTextPosition={HelperTextPosition.top}
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.CONTACT_REP)}
                onFocus={(e) => onFocus(e, STEP_LABELS.CONTACT_REP)}
              />
            )}
          />
        </Box>
        <Box className={Globals.FlexColumn} marginBottom={'1.5rem'}></Box>
      </Box>
    </>
  );
}
