import { STEP_NAMES } from '../store/constants/stepConstants';
import stepperUtils from '../utils/stepperUtils';
import { updateLivingExpenses } from '../utils/stepUtils';
import utils from '../utils/utils';

export enum ACTIONS {
  REMOVE_STEP = 'remove-step',
  ADD_STEP = 'add-step',
  ACTIVATE_STEP = 'activate-step',
  DEACTIVATE_STEP = 'deactivate-step',
  ADD_STEP_VISIBLE = 'add-step-visible',
  REMOVE_STEP_VISIBLE = 'remove-step-visible',
  ADD_STEP_VISITED = 'add-step-visited',
  REMOVE_STEP_VISITED = 'remove-step-visited',
  UPDATE_STEP_VALUE = 'update-step-value',
  UPDATE_TOTAL_NEED = 'update-total-need',
  UPDATE_LIVING_EXPENSES = 'update-living-expenses'
}

export interface ActionTypes {
  type: ACTIONS;
  payload: {
    stepName?: string;
    fieldName?: string;
    value?: string;
    activeStep?: number | string;
  };
}

export function stepReducer(steps: any, action: ActionTypes) {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_STEP:
      if (payload.stepName === STEP_NAMES.MORTGAGE) {
        steps.forEach((step: { name: string }, idx: number) => {
          if (idx === 2 && step.name !== STEP_NAMES.MORTGAGE) {
            steps.splice(idx, 0, {
              name: 'mortgage',
              heading: 'Mortgage',
              label: 'Morgage',
              active: false,
              enabled: true,
              visible: true,
              visited: false,
              valid: true,
              hasLine: true,
              showValue: true,
              details: false,
              value: '',
              fields: [],
              format: 'usd'
            });
          }
        });
        return steps;
      }
      if (payload.stepName === STEP_NAMES.EDUCATION_EXPENSES) {
        // living expenses exists do nothing
        let livingExists: boolean = false;
        let mortgageExists: boolean = false;
        let edExists: boolean = false;
        let insertIdx: number = 0;

        steps.forEach((step: { name: string }, idx: number) => {
          if (step.name === STEP_NAMES.EDUCATION_EXPENSES) {
            edExists = true;
            return step;
          }

          if (step.name === STEP_NAMES.MORTGAGE) {
            mortgageExists = true;
          }
        });

        if (edExists) {
          return steps;
        }

        if (mortgageExists) {
          insertIdx = 4;
        }

        if (!mortgageExists) {
          insertIdx = 3;
        }

        if (insertIdx) {
          steps.splice(insertIdx, 0, {
            name: 'educationExpenses',
            heading: 'Education Expenses',
            label: 'Education Expenses',
            description:
              'When you pass away, life insurance could help your family members pay for college, university or trade school.',
            active: false,
            enabled: true,
            visible: true,
            visited: false,
            valid: true,
            hasLine: true,
            showValue: true,
            details: false,
            value: '',
            fields: [],
            format: 'usd'
          });
          return steps;
        }
        return steps;
      }
      if (payload.stepName === STEP_NAMES.LIVING_EXPENSES) {
        // living expenses exists do nothing
        let livingExists: boolean = false;
        let mortgageExists: boolean = false;
        let edExists: boolean = false;
        let insertIdx: number = 0;

        steps.forEach((step: { name: string }, idx: number) => {
          if (step.name === STEP_NAMES.LIVING_EXPENSES) {
            livingExists = true;
            return step;
          }

          if (step.name === STEP_NAMES.MORTGAGE) {
            mortgageExists = true;
          }

          if (step.name === STEP_NAMES.EDUCATION_EXPENSES) {
            edExists = true;
          }
        });

        if (livingExists) {
          return steps;
        }

        if (mortgageExists && !edExists) {
          insertIdx = 4;
        }

        if (!mortgageExists && edExists) {
          insertIdx = 4;
        }

        if (!mortgageExists && !edExists) {
          insertIdx = 3;
        }

        if (mortgageExists && edExists) {
          insertIdx = 5;
        }

        if (insertIdx) {
          steps.splice(insertIdx, 0, {
            name: 'livingExpenses',
            heading: 'Living Expenses',
            label: 'Living Expenses',
            description:
              'Your loved ones may need help covering monthly expenses, as they will not have the income you currently bring into the household. Monthly living expenses include items like groceries, utilities, vehicle or transportation costs, clothing, and entertainment.',
            active: false,
            enabled: true,
            visible: true,
            visited: false,
            valid: true,
            hasLine: true,
            showValue: true,
            details: false,
            value: '',
            doNotList: false,
            fields: [
              {
                name: 'monthlyLivingExpenses',
                label: 'Monthly Living Expenses',
                valid: true,
                format: 'usd',
                type: 'text',
                value: '',
                doNotList: true,
                options: []
              },
              {
                name: 'yearsNeeded',
                label: 'Number Of Years Needed',
                valid: true,
                format: 'text',
                type: 'text',
                value: '',
                doNotList: true,
                options: []
              }
            ],
            format: 'usd'
          });
          return steps;
        }
        return steps;
      }
    case ACTIONS.REMOVE_STEP:
      return steps.filter(
        (step: { name: string }) => step.name !== payload.stepName
      );
    case ACTIONS.ACTIVATE_STEP:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, enabled: true, active: true };
        }
        return step;
      });
    case ACTIONS.DEACTIVATE_STEP:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, enabled: false, active: false };
        }
        return step;
      });
    case ACTIONS.ADD_STEP_VISIBLE:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, visible: true };
        }
        return step;
      });
    case ACTIONS.REMOVE_STEP_VISIBLE:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, visible: false };
        }
        return step;
      });
    case ACTIONS.ADD_STEP_VISITED:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, visited: true };
        }
        return step;
      });
    case ACTIONS.REMOVE_STEP_VISITED:
      return steps.map((step: { name: string }) => {
        if (step.name === payload.stepName) {
          return { ...step, visited: false };
        }
        return step;
      });
    case ACTIONS.UPDATE_STEP_VALUE:
      return steps.map((step: any, idx: number) => {
        if (step.name === payload.stepName && step.fields.length) {
          step.fields.map((field: any, idx: number) => {
            if (field.name === payload.fieldName) {
              field.value = payload.value;
            }
            return field;
          });
          if (step.name === STEP_NAMES.LIVING_EXPENSES) {
            let monthly;
            let yearsNeeded;
            step.fields.forEach((field: any, idx: number) => {
              if (field.name === 'monthlyLivingExpenses') {
                monthly = isNaN(parseInt(field.value))
                  ? 0
                  : parseInt(field.value);
              }
              if (field.name === 'yearsNeeded') {
                yearsNeeded = isNaN(parseInt(field.value))
                  ? 0
                  : parseInt(field.value);
              }
            });
            if (monthly && yearsNeeded) {
              step.value = updateLivingExpenses(monthly, yearsNeeded);
            } else {
              step.value = 0;
            }
            return step;
          }
        }
        if (step.name === payload.stepName && !step.fields.length) {
          step.value = payload.value;
        }
        return step;
      });
    case ACTIONS.UPDATE_TOTAL_NEED:
      let tempSum = 0;
      let totalIndex = steps.findIndex((step: any) => step.name === 'total');
      steps.forEach((step: any) => {
        if (
          !utils.isEmpty(step.value) &&
          utils.isNumeric(String(step.value).replace('$', '').replace(',', ''))
        ) {
          if (step.name === STEP_NAMES.CURRENT_ASSETS) {
            tempSum -= Number(
              String(step.value).replace('$', '').replace(',', '')
            );
          } else {
            tempSum += Number(
              String(step.value).replace('$', '').replace(',', '')
            );
          }
        } else {
          step.value = 0;
          tempSum += 0;
        }
      });
      if (tempSum < 0) {
        tempSum = 0;
      }
      steps[totalIndex].total = Math.round(tempSum);
      return steps;
    default:
      return steps;
  }
}
