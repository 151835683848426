export const FinalExpensesValues = {
  '0': '$0',
  '5000': '$5,000',
  '10000': '$10,000',
  '15000': '$15,000',
  '20000': '$20,000',
  '25000': '$25,000',
  '30000': '$30,000',
  '35000': '$35,000',
  '40000': '$40,000',
  '45000': '$45,000',
  '50000': '$50,000'
};

export const NumberOfChildren = {
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9'
};
