import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLButton, WLInput } from '../../../../../../design-library';
import { SelectVariant } from '../../../../../../design-library/Forms/Select/Select';
import {
  trackItemizationEndMixpanel,
  trackItemizationStartMixpanel,
  trackItemizationUpdateMixpanel,
} from '../../../../../../utils/mixpanelUtils';
import FormikFieldAdapter from '../../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../../global.module.scss';
import {
  STEP_LABELS,
  STEP_NAMES,
} from '../../../../store/constants/stepConstants';
import itemizeUtils from '../../../../utils/itemizeUtils';
import utils from '../../../../utils/utils';
import CurrencyPrefix from '../../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../../InputContainer/InputContainer';

export default function CurrentAssetsItemizeForm(props: any) {
  const {
    handleItemizeTotals,
    handleItemizeChange,
    values,
    errors,
    onBlur,
    onFocus,
  } = props;
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    trackItemizationStartMixpanel(STEP_LABELS.CURRENT_ASSETS, mixpanel);
    return () => {
      trackItemizationEndMixpanel(STEP_LABELS.CURRENT_ASSETS, mixpanel);
    };
  }, []);

  const getInFormTotal = () => {
    return itemizeUtils.getItemizedTotals('currentAssets', values);
  };

  const handleChange = (e: any) => {
    handleItemizeChange(e);
  };

  const handleTotal = () => {
    trackItemizationUpdateMixpanel(STEP_LABELS.CURRENT_ASSETS, mixpanel);
    let sum = getInFormTotal();
    handleItemizeTotals(STEP_NAMES.CURRENT_ASSETS, 'currentAssets', sum);
  };

  const validateFields = () => {
    let errorsExist: boolean = false;
    Object.values(errors).map((el, idx) => {
      if (!utils.isEmpty(el) && el !== 'Required') {
        errorsExist = true;
      }
    });
    if (errorsExist) {
      return true;
    } else {
      return errorsExist;
    }
  };

  return (
    <section className={Globals.Section} style={{ minHeight: '1000px' }}>
      <h2
        className={`${Globals.ParagraphLarge} ${Globals.Subheading}`}
        style={{ paddingBottom: '24px' }}
      >
        Help Me Calculate My Total Current Assets
      </h2>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsLifeInsurance"
            label="Existing Life Insurance Coverage"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsRetirement"
            label="Retirement"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
      </div>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsSavingsChecking"
            label="Savings, Checkings"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  autoFocus={false}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsStocksBonds"
            label="Stocks, Bonds"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  autoFocus={false}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
      </div>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsAnnuities"
            label="Annuities"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  autoFocus={false}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn} style={{ marginBottom: '1.5rem' }}>
          <FormikFieldAdapter
            name="currentAssetsOther"
            label="Other"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  onFocus={(e) => onFocus(e, STEP_LABELS.CURRENT_ASSETS)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.CURRENT_ASSETS)}
                  autoFocus={false}
                  onChange={handleChange}
                />
              </InputContainer>
            )}
          />
        </div>
      </div>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn}>
          <div id="currentAssetsTotalDiv" className={Globals.Subtotal}>
            <strong>Total: ${utils.numFormat(getInFormTotal())}</strong>
          </div>
        </div>
      </div>
      <div
        className={`${Globals.ButtonContainer} ${Globals.ButtonContainerFlex}`}
      >
        <WLButton
          type="button"
          className={Globals.ButtonBlue}
          aria-label="Update"
          isDisabled={validateFields()}
          onClick={handleTotal}
        >
          Update
        </WLButton>
      </div>
    </section>
  );
}
