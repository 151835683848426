import utils from './utils';

export default {
  formatValue: function (obj: any) {
    // If the obj's format key is NOT empty AND
    // the user's key-referenced value is NOT empty...
    if (!utils.isEmpty(obj.format) && !utils.isEmpty(obj.value)) {
      // Determine the appropriate format.
      // If the obj's format key is United States Dollars ('usd') AND
      // the user's key-referenced value is a number...
      if (obj.format === 'usd' && utils.isNumeric(obj.value)) {
        // Round the value and return it formatted as USD.
        return utils.numFormatUSDRound(obj.value);
        // Else if the obj's format key is text...
      } else if (obj.format === 'text') {
        // Return the user's key-referenced value.
        return obj.value;
      } else {
        return '';
      }
    } else {
      return '';
    }
  },

  visibleDetails: function (arr: [{}]) {
    // TODO: Refactor this function when user object data is migrated into steps object. -NE 20210412
    let newArr = [];
    // for (let i = 0; i < arr.length; i++) {
    //   if (
    //     Object.prototype.toString.call(arr[i].donNotList) !==
    //       '[object Object]' &&
    //     !arr[i].doNotList
    //   ) {
    //     newArr.push(arr[i]);
    //   } else if (
    //     Object.prototype.toString.call(arr[i].doNotList) === '[object Object]'
    //   ) {
    //     let objName = arr[i].name;
    //     for (const value in Object.values(arr[i].doNotList)) {
    //       if (this.user[objName] !== value) newArr.push(arr[i]);
    //     }
    //   }
    // }
    // return ,newArr;
  },
  visibibleSteps: function (steps: any) {
    return steps.filter(
      (step: any) => step.visible === true && !step.doNotList
    );
  }
};
