import { Box } from '@chakra-ui/react';
import { Form, FormikProps, withFormik } from 'formik';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React, { useReducer, useState } from 'react';

import { Gender, HomeStatus } from '../../../../apis';
import { ApiConfiguration, LifeInsuranceCalculatorApi } from '../../../../apis';
import { WLButton } from '../../../../design-library';
import { useRepSearch } from '../../../../hooks/useRepSearch';
import { trackMixpanel } from '../../../../utils/mixpanelUtils';
import { isZipValidationError } from '../../../../utils/validation.utils';
import * as Globals from '../../global.module.scss';
import { MasterFormValues } from '../../Interface/types';
import LICalcValidator from '../../LICalc.validator';
import { ACTIONS, stepReducer } from '../../reducers/stepReducers';
import { STEP_NAMES } from '../../store/constants/stepConstants';
import { stepState } from '../../store/state/stepState';
import { mapToCalculatorRequest } from '../../utils/LICalcFormUtils';
import stepperUtils from '../../utils/stepperUtils';
import { updateLivingExpenses } from '../../utils/stepUtils';
import Ad from '../Ad/Ad';
import Fader from '../Fader/Fader';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ItemizeFlyout from '../ItemizeFlyout/ItemizeFlyout';
import * as Styles from '../MasterForm/MasterForm.module.scss';
import Stepper from '../Stepper/Stepper';
import {
  ContactRep,
  CurrentAssets,
  Debt,
  EducationExpenses,
  FinalExpenses,
  GettingStarted,
  LivingExpenses,
  Mortgage,
  Thankyou,
} from '../Steps/index';

interface MasterFormProps {
  utmParameters: string | null;
  custom1: string | null;
  total: string | null;
}

const MasterForm: React.FC<MasterFormProps & FormikProps<MasterFormValues>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  validateField,
  setStatus,
  setFieldError,
  isValid,
  status,
  utmParameters,
  custom1,
  total,
}) => {
  const mixpanel = useMixpanel();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, dispatch] = useReducer(stepReducer, stepState);
  const [showItemizeFlyout, setshowItemizeFlyout] = useState(false);
  const [itemizeForm, setItemizeForm] = useState('');
  const [repLookupComplete, repData] = useRepSearch();

  React.useEffect(() => {
    setFieldValue('utmParameters', utmParameters);
  }, [utmParameters]);

  React.useEffect(() => {
    setFieldValue('custom1', repData?.producerId);
  }, [repData]);

  React.useEffect(() => {
    setFieldValue('total', steps[steps.length - 1].total);
  }, [steps[steps.length - 1].total]);

  React.useEffect(() => {
    if (status === 'success' && isSubmitting === false) {
      window.dataLayer.push({
        event: 'generate_lead_sales',
        status: 'success',
        formType: 'salesLeadGenForm',
        formLocation: 'Life Insurance Calculator',
      });

      mixpanel.track('Contact a Representative - Submit', {
        Clicked: true,
      });
      handleIncrementStep();
    }
  }, [status, isSubmitting]);

  React.useEffect(() => {
    let expenses = isNaN(parseInt(values.monthlyLivingExpenses))
      ? 0
      : parseInt(values.monthlyLivingExpenses);
    let yearsNeeded = isNaN(parseInt(values.yearsNeeded))
      ? 0
      : parseInt(values.yearsNeeded);
    if (
      parseInt(values.yearsNeeded) > 0 &&
      parseInt(values.monthlyLivingExpenses) > 0
    ) {
      setFieldValue(
        'livingExpenses',
        updateLivingExpenses(expenses, yearsNeeded),
      );
    }
  }, [values.yearsNeeded, values.monthlyLivingExpenses]);

  React.useEffect(() => {
    if (
      values.livingSituation &&
      values.livingSituation.toLowerCase() === 'own'
    ) {
      dispatch({
        type: ACTIONS.ADD_STEP,
        payload: { stepName: STEP_NAMES.MORTGAGE },
      });
    } else if (
      values.livingSituation &&
      values.livingSituation.toLowerCase() === 'rent'
    ) {
      dispatch({
        type: ACTIONS.REMOVE_STEP,
        payload: { stepName: STEP_NAMES.MORTGAGE },
      });
    }

    dispatch({
      type: ACTIONS.UPDATE_TOTAL_NEED,
      payload: {
        stepName: STEP_NAMES.MORTGAGE,
        value: '',
        activeStep,
      },
    });
    setFieldValue('mortgage', '');
  }, [values.livingSituation]);

  React.useEffect(() => {
    if (parseInt(values.numberOfChildren) > 0) {
      // create child fields in formik state to be passed
      dispatch({
        type: ACTIONS.ADD_STEP,
        payload: { stepName: STEP_NAMES.EDUCATION_EXPENSES },
      });
      dispatch({
        type: ACTIONS.ADD_STEP,
        payload: { stepName: STEP_NAMES.LIVING_EXPENSES },
      });
    } else {
      dispatch({
        type: ACTIONS.REMOVE_STEP,
        payload: { stepName: STEP_NAMES.EDUCATION_EXPENSES },
      });
      if (
        values.maritalStatus.toLowerCase() === 'single' ||
        values.maritalStatus.toLowerCase() === 'unknown'
      ) {
        dispatch({
          type: ACTIONS.REMOVE_STEP,
          payload: { stepName: STEP_NAMES.LIVING_EXPENSES },
        });

        dispatch({
          type: ACTIONS.UPDATE_TOTAL_NEED,
          payload: {
            stepName: STEP_NAMES.LIVING_EXPENSES,
            value: '',
            activeStep,
          },
        });
        setFieldValue('monthlyLivingExpenses', '');
        setFieldValue('yearsNeeded', '');
      }
    }
    dispatch({
      type: ACTIONS.UPDATE_TOTAL_NEED,
      payload: {
        stepName: STEP_NAMES.EDUCATION_EXPENSES,
        value: '',
        activeStep,
      },
    });
    setFieldValue('educationExpenses', '');
    for (let i = 1; i <= parseInt(values.numberOfChildren); i++) {
      setFieldValue(`child-${i}`, '');
    }
  }, [values.numberOfChildren]);

  React.useEffect(() => {
    let children = isNaN(parseInt(values.numberOfChildren))
      ? 0
      : parseInt(values.numberOfChildren);
    if (
      (values.maritalStatus.toLowerCase() === 'single' ||
        values.maritalStatus.toLowerCase() === 'unknown') &&
      children === 0
    ) {
      dispatch({
        type: ACTIONS.REMOVE_STEP,
        payload: { stepName: STEP_NAMES.LIVING_EXPENSES },
      });

      dispatch({
        type: ACTIONS.UPDATE_TOTAL_NEED,
        payload: {
          stepName: STEP_NAMES.LIVING_EXPENSES,
          value: '',
          activeStep,
        },
      });
      setFieldValue('monthlyLivingExpenses', '');
      setFieldValue('yearsNeeded', '');
    } else if (
      values.maritalStatus.toLowerCase() === 'married' ||
      (values.maritalStatus.toLowerCase() === 'partner' && children === 0)
    ) {
      dispatch({
        type: ACTIONS.ADD_STEP,
        payload: { stepName: STEP_NAMES.LIVING_EXPENSES },
      });

      dispatch({
        type: ACTIONS.UPDATE_TOTAL_NEED,
        payload: {
          stepName: STEP_NAMES.LIVING_EXPENSES,
          value: '',
          activeStep,
        },
      });
      setFieldValue('livingExpenses', '');
      setFieldValue('yearsNeeded', '');
    }
  }, [values.maritalStatus, values.numberOfChildren]);

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>, stepName: string) => {
    handleBlur(e);

    trackMixpanel(e, stepName, 'blur', mixpanel);
  };

  const onFocus = (
    e: React.ChangeEvent<HTMLInputElement>,
    stepName: string,
  ) => {
    handleBlur(e);

    trackMixpanel(e, stepName, 'focus', mixpanel);
  };

  const handleItemizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name);
    handleChange(e);
  };

  const handleAddChildren = (values: { [key: string]: any }) => {
    let childArray = [];
    for (let i = 1; i <= parseInt(values.numberOfChildren); i++) {
      childArray.push({ [`child-${i}`]: values[`child-${i}`] });
    }
    setFieldValue('children', childArray);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name);
    // call formik handle change
    handleChange(e);

    // call dispatch function or updating value in step state
    dispatch({
      type: ACTIONS.UPDATE_STEP_VALUE,
      payload: {
        stepName: steps[activeStep].name,
        fieldName: e.target.name,
        value: e.target.value,
        activeStep,
      },
    });
    dispatch({
      type: ACTIONS.UPDATE_TOTAL_NEED,
      payload: {
        stepName: steps[activeStep].name,
        fieldName: e.target.name,
        value: e.target.value,
        activeStep,
      },
    });
  };

  const handleStepOneValidation = () => {
    if (
      !values.age ||
      errors.age ||
      !values.gender ||
      errors.gender ||
      !values.livingSituation ||
      errors.livingSituation ||
      values.livingSituation.toLowerCase() === 'unknown' ||
      !values.maritalStatus ||
      errors.maritalStatus ||
      !values.state ||
      errors.state
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleItemizeTotals = (
    stepName: string,
    fieldName: string,
    total: string,
  ) => {
    setFieldValue(fieldName, total, true);
    dispatch({
      type: ACTIONS.UPDATE_STEP_VALUE,
      payload: {
        stepName: stepName,
        value: total,
        activeStep,
      },
    });
    dispatch({
      type: ACTIONS.UPDATE_TOTAL_NEED,
      payload: {
        stepName: stepName,
        value: total,
        activeStep,
      },
    });
    handleCloseFlyout();
  };

  const handleFlyout = (showFlyout: boolean, form: string) => {
    setshowItemizeFlyout(() => showFlyout);
    setItemizeForm(() => form);
  };

  const handleCloseFlyout = (form?: string) => {
    if (form) {
      mixpanel.track(`${form} - Itemization Close`, {
        Clicked: true,
      });
    }
    setshowItemizeFlyout(false);
    if (window) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const handleIncrementStep = () => {
    mixpanel.track('Life Insurance Calculator - Next', {
      'Current Step': steps[activeStep].name,
      'Destination Step': steps[activeStep + 1].name,
      Clicked: true,
    });

    if (window) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    dispatch({
      type: ACTIONS.DEACTIVATE_STEP,
      payload: {
        stepName: steps[activeStep].name,
      },
    });
    dispatch({
      type: ACTIONS.ADD_STEP_VISITED,
      payload: {
        stepName: steps[activeStep].name,
      },
    });

    dispatch({
      type: ACTIONS.ACTIVATE_STEP,
      payload: {
        stepName: steps[activeStep + 1].name,
      },
    });
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleDecrementStep = () => {
    mixpanel.track('Life Insurance Calculator - Previous', {
      'Current Step': steps[activeStep].name,
      'Destination Step': steps[activeStep - 1].name,
      Clicked: true,
    });

    if (window) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    dispatch({
      type: ACTIONS.DEACTIVATE_STEP,
      payload: {
        stepName: steps[activeStep].name,
      },
    });
    dispatch({
      type: ACTIONS.ADD_STEP_VISITED,
      payload: {
        stepName: steps[activeStep].name,
      },
    });

    dispatch({
      type: ACTIONS.ACTIVATE_STEP,
      payload: {
        stepName: steps[activeStep - 1].name,
      },
    });
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (e: any, idx: number) => {
    if (window) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // close any open flyouts
    handleCloseFlyout();
    // deactivate previously active step
    dispatch({
      type: ACTIONS.DEACTIVATE_STEP,
      payload: {
        stepName: steps[activeStep].name,
      },
    });
    // set previous step to visited
    dispatch({
      type: ACTIONS.ADD_STEP_VISITED,
      payload: {
        stepName: steps[activeStep].name,
      },
    });
    // activate clicked step
    dispatch({
      type: ACTIONS.ACTIVATE_STEP,
      payload: {
        stepName: steps[idx].name,
      },
    });
    // set clicked step to the active step in state
    setActiveStep(() => {
      return idx;
    });
  };

  const displaySteps = (
    steps: [{ name: string }],
    activeStep: number,
    totalIdx: number,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  ) => {
    switch (steps[activeStep].name) {
      case STEP_NAMES.GETTING_STARTED:
        return (
          <GettingStarted
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            errors={errors}
          />
        );

      case STEP_NAMES.FINAL_EXPENSES:
        return (
          <FinalExpenses
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            errors={errors}
          />
        );
      case STEP_NAMES.MORTGAGE:
        return (
          <Mortgage
            onChange={onChange}
            errors={errors}
            setFieldError={setFieldError}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case STEP_NAMES.DEBT:
        return (
          <Debt
            onChange={onChange}
            handleFlyout={handleFlyout}
            errors={errors}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case STEP_NAMES.EDUCATION_EXPENSES:
        return (
          <EducationExpenses
            onChange={onChange}
            handleFlyout={handleFlyout}
            errors={errors}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case STEP_NAMES.LIVING_EXPENSES:
        return (
          <LivingExpenses
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            errors={errors}
          />
        );
      case STEP_NAMES.CURRENT_ASSETS:
        return (
          <CurrentAssets
            onChange={onChange}
            handleFlyout={handleFlyout}
            errors={errors}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case STEP_NAMES.CONTACT_REP:
        return (
          <ContactRep
            onChange={onChange}
            totalIdx={totalIdx}
            steps={steps}
            errors={errors}
            onBlur={onBlur}
            onFocus={onFocus}
            repData={repData}
          />
        );
      case STEP_NAMES.THANKYOU:
        return (
          <Thankyou
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            repData={repData}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <ItemizeFlyout
        showItemizeFlyout={showItemizeFlyout}
        itemizeForm={itemizeForm}
        onBlur={onBlur}
        onFocus={onFocus}
        handleItemizeTotals={handleItemizeTotals}
        handleCloseFlyout={handleCloseFlyout}
        handleItemizeChange={handleItemizeChange}
        numberChildren={values.numberOfChildren}
        handleAddChildren={handleAddChildren}
        values={values}
        errors={errors}
      />
      <Box className={Styles.CardShadow}>
        <Box
          className={`${Styles.LeftColumn} ${
            activeStep !== 0 ? Styles.LeftColumnGray : ''
          }`}
        >
          <Box className={Styles.LeftColumnHeader}>
            <Box className={Styles.HeadlineDivider}>
              <Box className={Styles.HeadlineDividerItem}>
                <Box className={Styles.HeadlineDividerItemLine}></Box>
              </Box>
              <Box className={Styles.HeadlineDividerItem}>
                <h1 className={Styles.HeadlineDividerItemText}>
                  Life Insurance Calculator
                </h1>
              </Box>
            </Box>
          </Box>
          {activeStep === 0 && <Ad />}
          {activeStep > 0 && (
            <Fader>
              <Stepper
                activeStep={activeStep}
                totalSteps={steps.length}
                steps={steps}
                totalNeed={steps[steps.length - 1].total}
                onClick={handleStepClick}
                errors={errors}
                repData={repData || undefined}
              />
            </Fader>
          )}
        </Box>
        <Box
          className={`${Styles.RightColumn} ${
            activeStep === 0 ? Styles.RightColumnHasBorder : ''
          }`}
        >
          {activeStep !== 0 && <Header />}
          <Form className={Styles.MasterForm} onSubmit={handleSubmit}>
            <Box
              className={Globals.RightColumnContent}
              style={{
                margin:
                  steps[activeStep].name === STEP_NAMES.CONTACT_REP
                    ? '40px auto 40px'
                    : '',
              }}
            >
              <Fader>
                {displaySteps(steps, activeStep, steps.length - 1, onChange)}
              </Fader>
              <Box
                className={`${
                  activeStep === 0 ? Globals.FieldInTwoColumns : ''
                }`}
              >
                <Box
                  className={`${Styles.ButtonContainer} ${Styles.ButtonContainerFlex}`}
                >
                  {activeStep === 0 && (
                    <WLButton
                      className={Styles.ButtonBlue}
                      width="100%"
                      type="button"
                      onClick={handleIncrementStep}
                      isDisabled={handleStepOneValidation()}
                    >
                      Begin
                    </WLButton>
                  )}
                  {activeStep >= 1 &&
                    activeStep < stepperUtils.visibibleSteps(steps).length && (
                      <>
                        <WLButton
                          className={Styles.ButtonTransparent}
                          type="button"
                          onClick={handleDecrementStep}
                        >
                          Previous
                        </WLButton>
                        <WLButton
                          className={Styles.ButtonBlue}
                          type="button"
                          onClick={handleIncrementStep}
                        >
                          Next
                        </WLButton>
                      </>
                    )}
                  {activeStep === stepperUtils.visibibleSteps(steps).length && (
                    <>
                      <WLButton
                        className={Styles.ButtonTransparent}
                        type="button"
                        uppercase
                        onClick={handleDecrementStep}
                      >
                        Previous
                      </WLButton>
                      <WLButton
                        className={Styles.ButtonRed}
                        type="submit"
                        uppercase
                        isLoading={isSubmitting ? true : false}
                      >
                        Submit
                      </WLButton>
                    </>
                  )}
                </Box>
              </Box>

              {steps[activeStep].name === STEP_NAMES.GETTING_STARTED ? (
                <>
                  <h3 className={`${Globals.AsH4} ${Globals.TextNavy}`}>
                    How does this work?
                  </h3>
                  <div
                    className={`${Globals.FlexRow} ${Globals.JustifyContentSpaceBetween} ${Globals.FlexWrap}`}
                  >
                    <div className={`${Globals.FieldInTwoColumns}`}>
                      <p className={Globals.Paragraph}>
                        We'll ask for some basic financial information to
                        calculate an estimate of your life insurance need. You
                        can use this number as a starting point, then discuss
                        your life insurance options with{' '}
                        {repData && repData.alias
                          ? `your WoodmenLife Representative, ${repData.alias} ${repData.lastName}.`
                          : repData
                          ? `your WoodmenLife Representative, ${repData.firstName} ${repData.lastName}.`
                          : `a WoodmenLife Representative.`}
                      </p>
                    </div>
                    <div className={`${Globals.FieldInTwoColumns}`}>
                      <p className={Globals.Paragraph}>
                        This is not an official application for life insurance.
                        No medical information is needed at this time.
                      </p>
                    </div>
                  </div>
                </>
              ) : steps[activeStep].name === STEP_NAMES.CONTACT_REP ? (
                <>
                  <p className={`${Globals.TextSmall} ${Globals.TextGray50}`}>
                    This is a solicitation of insurance and an agent may contact
                    you. By submitting the above request, I consent to receive
                    phone calls from a licensed insurance producer on behalf of
                    Woodmen of the World Life Insurance Society (WoodmenLife)
                    and its affiliates at the phone number(s) above regarding
                    WoodmenLife products and services. I understand that I can
                    contact a Sales Representative directly to make a purchase.
                  </p>
                </>
              ) : (
                ''
              )}
            </Box>
          </Form>
        </Box>
      </Box>
      <Footer repData={repData} />
    </>
  );
};

const EnhancedForm = withFormik<MasterFormProps, MasterFormValues>({
  mapPropsToValues: (props) => ({
    age: '',
    gender: Gender.Unknown,
    maritalStatus: '',
    numberOfChildren: '',
    children: [],
    [`child-1`]: '',
    [`child-2`]: '',
    [`child-3`]: '',
    [`child-4`]: '',
    [`child-5`]: '',
    [`child-6`]: '',
    [`child-7`]: '',
    [`child-8`]: '',
    [`child-9`]: '',
    [`child-10`]: '',
    livingSituation: HomeStatus.Unknown,
    state: '',
    finalExpenses: '',
    mortgage: '',
    debt: '',
    educationExpenses: '',
    livingExpenses: '',
    livingExpensesYears: '',
    monthlyLivingExpenses: '',
    yearsNeeded: '',
    currentAssets: '',
    firstName: '',
    lastName: '',
    phone: '',
    zipCode: '',
    email: '',
    debtAutoLoans: '',
    debtCreditCards: '',
    debtPersonalLoans: '',
    debtOther: '',
    currentAssetsLifeInsurance: '',
    currentAssetsRetirement: '',
    currentAssetsSavingsChecking: '',
    currentAssetsStocksBonds: '',
    currentAssetsAnnuities: '',
    currentAssetsOther: '',
    refPage: 'Life Insurance Calculator',
    utmParameters: props.utmParameters,
    custom1: props.custom1,
    total: props.total,
  }),
  validate: LICalcValidator,
  handleSubmit: async (values, formikBag): Promise<void> => {
    const { setStatus, setFieldError, setSubmitting, setFieldValue } =
      formikBag;
    setSubmitting(true);
    try {
      const api = new LifeInsuranceCalculatorApi(
        new ApiConfiguration({
          basePath: process.env.GATSBY_PUBLIC_API_PATH,
        }),
      );

      const request = mapToCalculatorRequest(values);
      await api.lifeInsuranceCalculatorPost(request);

      setStatus('success');
      setSubmitting(false);
    } catch (error: any) {
      const isZipCodeInvalid = await isZipValidationError(error);

      if (isZipCodeInvalid) {
        setFieldError('zipCode', 'Invalid Zip Code');
        return;
      }

      setStatus('error');
      setSubmitting(false);
    }
  },
  displayName: 'MasterForm',
})(MasterForm);

export default EnhancedForm;
