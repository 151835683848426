import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLInput } from '../../../../../design-library';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import CurrencyPrefix from '../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../InputContainer/InputContainer';
export default function LivingExpenses(props: any) {
  const mixpanel = useMixpanel();
  const { onChange, onBlur, onFocus } = props;

  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.LIVING_EXPENSES, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.LIVING_EXPENSES, mixpanel);
    };
  }, []);

  return (
    <>
      <h2 className={Globals.StepHeading}>Living Expenses for Loved Ones</h2>
      <p className={Globals.Paragraph}>
        Your loved ones may need help covering monthly expenses, as they will
        not have the income you currently bring into the household. Monthly
        living expenses include items like groceries, utilities, vehicle or
        transportation costs, clothing, and entertainment.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ height: '110px' }}>
          <FormikFieldAdapter
            name="monthlyLivingExpenses"
            label="Monthly Living Expenses"
            renderField={(adapterProps) => (
              <>
                <InputContainer maxWidth={'none'} style={{ marginBottom: '0' }}>
                  <CurrencyPrefix />
                  <WLInput
                    {...adapterProps}
                    variant={SelectVariant.FLUSHED}
                    autoFocus={false}
                    onChange={(e) => onChange(e)}
                    onFocus={(e) => onFocus(e, STEP_LABELS.LIVING_EXPENSES)}
                    onBlur={(e) => onBlur(e, STEP_LABELS.LIVING_EXPENSES)}
                    paddingLeft={'60px'}
                  />
                </InputContainer>
              </>
            )}
          />
        </div>
        <div className={Globals.FlexColumn}></div>
      </div>
      <p className={Globals.Paragraph}>
        Choose the number of years you&rsquo;d like to cover these expenses.
        Take into account that many spouses or partners choose to take time off
        work while grieving.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div
          className={Globals.FlexColumn}
          style={{ paddingBottom: '24px', minHeight: '90px' }}
        >
          <FormikFieldAdapter
            name="yearsNeeded"
            label="Number of Years Needed"
            renderField={(adapterProps) => (
              <WLInput
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                autoFocus={false}
                onChange={(e) => onChange(e)}
                onFocus={(e) => onFocus(e, STEP_LABELS.LIVING_EXPENSES)}
                onBlur={(e) => onBlur(e, STEP_LABELS.LIVING_EXPENSES)}
              />
            )}
          />
        </div>
        <div className={Globals.FlexColumn}></div>
      </div>
      <p className={`${Globals.Paragraph} ${Globals.ParagraphSmall}`}>
        Total living expenses are adjusted for annual inflation. Inflation is
        based on the Consumer Price Index (CPI) &ndash; a common measure of
        inflation. From 1925 through 2019, the long-term average for the CPI has
        been <strong>2.9%</strong> annually.
      </p>
    </>
  );
}
