export const stepState = [
  {
    name: 'Getting Started',
    label: 'Getting Started',
    heading: "Let's get started on your customized estimate",
    active: true,
    enabled: true,
    visible: true,
    visited: true,
    valid: true,
    hasLine: false,
    showValue: false,
    details: true,
    fields: [
      {
        name: 'age',
        label: 'Age',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      },
      {
        name: 'gender',
        label: 'Gender',
        valid: true,
        format: 'text',
        type: 'select',
        value: '',
        doNotList: false,
        options: []
      },
      {
        name: 'maritalStatus',
        label: 'Marital Status',
        valid: true,
        format: 'text',
        type: 'select',
        value: '',
        doNotList: false,
        options: []
      },
      {
        name: 'numberOfChildren',
        label: 'Number of Children',
        valid: true,
        format: 'text',
        type: 'select',
        value: '',
        doNotList: {
          value: '0'
        },
        // doNotList: {
        //   value: '0'
        // },
        options: []
      },
      {
        name: 'livingSituation',
        label: 'Home Ownership',
        valid: true,
        format: 'text',
        type: 'select',
        value: '',
        doNotList: false,
        options: []
      },
      {
        name: 'state',
        label: 'State',
        valid: true,
        format: 'text',
        type: 'select',
        value: '',
        doNotList: false,
        options: []
      }
    ]
  },
  {
    name: 'finalExpenses',
    label: 'Final Expenses',
    heading: 'Final Expenses',
    description:
      'The average funeral costs up to $10,000<sup>*</sup>. Life insurance can be used to pay for funeral services, but also items like medical bills and legal fees.',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    fields: [],
    format: 'usd'
  },
  {
    name: 'mortgage',
    heading: 'Mortgage',
    label: 'Mortgage',
    description:
      'Enter the current outstanding principal balance on your mortgage.',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    fields: [],
    format: 'usd'
  },
  {
    name: 'debt',
    heading: 'Debt',
    label: 'Debt',
    description:
      'When you pass away, your family will need to pay any debts, including credit cards and loans.',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    fields: [],
    format: 'usd'
  },
  {
    name: 'educationExpenses',
    heading: 'Education Expenses',
    label: 'Education Expenses',
    description:
      'When you pass away, life insurance could help your family members pay for college, university or trade school.',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    fields: [],
    format: 'usd'
  },
  {
    name: 'livingExpenses',
    heading: 'Living Expenses',
    label: 'Living Expenses',
    description:
      'Your loved ones may need help covering monthly expenses, as they will not have the income you currently bring into the household. Monthly living expenses include items like groceries, utilities, vehicle or transportation costs, clothing, and entertainment.',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    doNotList: false,
    fields: [
      {
        name: 'monthlyLivingExpenses',
        label: 'Monthly Living Expenses',
        valid: true,
        format: 'usd',
        type: 'text',
        value: '',
        doNotList: true,
        options: []
      },
      {
        name: 'yearsNeeded',
        label: 'Number Of Years Needed',
        valid: true,
        format: 'text',
        type: 'text',
        value: '',
        doNotList: true,
        options: []
      }
    ],
    format: 'usd'
  },
  {
    name: 'currentAssets',
    label: 'Current Assets',
    heading: 'Current Assets',
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: true,
    details: false,
    value: '',
    fields: [],
    format: 'usd'
  },
  {
    name: 'contactRep',
    label: 'Contact a Representative',
    heading: "Let's Talk",
    active: false,
    enabled: true,
    visible: true,
    visited: false,
    valid: true,
    hasLine: true,
    showValue: false,
    details: true,
    value: '',
    doNotList: true,
    fields: [
      {
        name: 'firstName',
        label: 'First Name',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      },
      {
        name: 'phone',
        label: 'Phone Number',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      },
      {
        name: 'zipCode',
        label: 'ZIP Code',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      },
      {
        name: 'email',
        label: 'Email Address',
        valid: true,
        required: true,
        format: 'text',
        type: 'input',
        value: '',
        doNotList: false
      }
    ]
  },
  {
    name: 'thankYou',
    active: false,
    visible: false,
    doNotList: true
  },
  {
    name: 'total',
    visible: false,
    total: '',
    doNotList: true
  }
];
