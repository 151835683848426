import { LifeInsuranceCalculatorPostRequest } from '../../../apis';
import { MasterFormValues } from '../Interface/types';

export const mapToCalculatorRequest = (
  values: MasterFormValues,
): LifeInsuranceCalculatorPostRequest => {
  return {
    calculatorRequest: {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email || null,
      zipCode: values.zipCode,
      age: values.age,
      gender: values.gender,
      maritalStatus: values.maritalStatus,
      childCount: values.numberOfChildren,
      children: values.children,
      homeStatus: values.livingSituation,
      state: values.state,
      finalExpenses: values.finalExpenses,
      mortgage: values.mortgage,
      debt: values.debt,
      autoLoans: values.debtAutoLoans,
      creditCards: values.debtCreditCards,
      personalLoans: values.debtPersonalLoans,
      otherDebt: values.debtOther,
      educationExpenses: values.educationExpenses,
      livingExpensesPerMonth: values.monthlyLivingExpenses,
      livingExpensesYears: values.yearsNeeded,
      livingExpenses: values.livingExpenses,
      currentAssets: values.currentAssets,
      lifeInsurance: values.currentAssetsLifeInsurance,
      retirement: values.currentAssetsRetirement,
      stocksBonds: values.currentAssetsStocksBonds,
      cdSavingsCheckings: values.currentAssetsSavingsChecking,
      annuities: values.currentAssetsAnnuities,
      otherAssets: values.currentAssetsOther,
      refPage: values.refPage,
      utmParameters: values.utmParameters,
      custom1: values.custom1,
      total: values.total,
    },
  };
};
