import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLSelect } from '../../../../../design-library';
import { SelectVariant } from '../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../global.module.scss';
import { FinalExpensesValues } from '../../../store/constants/formFieldConstants';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';
import utils from '../../../utils/utils';

export default function FinalExpenses(props: any) {
  const { onChange, onBlur } = props;
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    trackStepStartMixpanel(STEP_LABELS.FINAL_EXPENSES, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.FINAL_EXPENSES, mixpanel);
    };
  }, []);

  const finalExpenseOptions = React.useMemo(
    () =>
      Object.entries(FinalExpensesValues).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [],
  );

  return (
    <>
      <h2 className={Globals.StepHeading}>Final Expenses</h2>
      <p className={Globals.Paragraph}>
        The average funeral costs up to $10,000<sup>*</sup>. Life insurance can
        be used to pay for funeral services, but also items like medical bills
        and legal fees.
      </p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn}>
          <FormikFieldAdapter
            name="finalExpenses"
            renderField={(adapterProps) => (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.FLUSHED}
                options={finalExpenseOptions}
                placeholder="Choose an amount..."
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e, STEP_LABELS.FINAL_EXPENSES)}
              />
            )}
          />
        </div>

        <div className={Globals.FlexColumn}></div>
      </div>
      <p className={`${Globals.Paragraph} ${Globals.ParagraphSmall}`}>
        <sup>*</sup>Source: National Funeral Directors Association, Sept. 2019
      </p>
    </>
  );
}
