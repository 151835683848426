import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React, { useState } from 'react';

import {
  CurrentAssetsItemizeForm,
  DebtItemizeForm,
  EducationExpensesItemizeForm,
  FinalExpensesItemizeForm
} from './Forms/index';
import * as Styles from './ItemizeFlyout.module.scss';
const ItemizeFlyout = (props: any) => {
  const mixpanel = useMixpanel();

  const {
    showItemizeFlyout,
    handleCloseFlyout,
    itemizeForm,
    handleItemizeTotals,
    handleItemizeChange,
    handleAddChildren,
    numberChildren,
    values,
    errors,
    onBlur,
    onFocus
  } = props;
  const [display, setDisplay] = useState({ display: 'none' });

  React.useEffect(() => {
    showItemizeFlyout
      ? setDisplay({ display: 'flex' })
      : setTimeout(() => {
          setDisplay({ display: 'none' });
        }, 500);

    if (showItemizeFlyout) {
      handleRootScrolling(false);
    } else {
      handleRootScrolling(true);
    }
  }, [showItemizeFlyout]);

  const handleClickOut = (e: any) => {
    if (e.target.id === 'Overlay') {
      mixpanel.track(`${itemizeForm} - Itemization Glass Cancel`, {
        Clicked: 'true'
      });
      handleCloseFlyout();
    }
  };

  const handleRootScrolling = (scroll: boolean) => {
    let root = document.getElementsByTagName('html')[0];
    if (document && !scroll) {
      root.style.overflow = 'hidden';
    } else if (document && scroll) {
      root.style.overflow = 'visible';
    }
  };

  return (
    <div
      className={`${Styles.FlyoutContainer} ${
        showItemizeFlyout && Styles.FlyoutVisible
      }`}
    >
      <div className={Styles.Flyout} style={display}>
        <div
          className={`${Styles.FlyoutOverlay}`}
          id="Overlay"
          onClick={handleClickOut}
        >
          <div
            className={`${Styles.MainFlyout} ${
              showItemizeFlyout
                ? Styles.MainFlyoutActive
                : Styles.MainFlyoutInactive
            }`}
          >
            <div className={Styles.FlyoutContent}>
              <span
                className={Styles.CloseButton}
                onClick={() => {
                  handleCloseFlyout(itemizeForm);
                }}
              >
                &times;
              </span>
              {showItemizeFlyout && itemizeForm === 'Final Expenses' && (
                <FinalExpensesItemizeForm
                  handleItemizeTotals={handleItemizeTotals}
                  handleItemizeChange={handleItemizeChange}
                  values={values}
                  errors={errors}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              )}
              {showItemizeFlyout && itemizeForm === 'Debt' && (
                <DebtItemizeForm
                  handleItemizeTotals={handleItemizeTotals}
                  handleItemizeChange={handleItemizeChange}
                  values={values}
                  errors={errors}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              )}
              {showItemizeFlyout && itemizeForm === 'Education Expenses' && (
                <EducationExpensesItemizeForm
                  handleItemizeTotals={handleItemizeTotals}
                  numberChildren={numberChildren}
                  handleItemizeChange={handleItemizeChange}
                  handleAddChildren={handleAddChildren}
                  errors={errors}
                  values={values}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              )}
              {showItemizeFlyout && itemizeForm === 'Current Assets' && (
                <CurrentAssetsItemizeForm
                  handleItemizeTotals={handleItemizeTotals}
                  handleItemizeChange={handleItemizeChange}
                  errors={errors}
                  values={values}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemizeFlyout;
