import { Box } from '@chakra-ui/react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLButton, WLInput } from '../../../../../../design-library';
import { SelectVariant } from '../../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../../global.module.scss';
import {
  STEP_LABELS,
  STEP_NAMES,
} from '../../../../store/constants/stepConstants';
import itemizeUtils from '../../../../utils/itemizeUtils';
import {
  trackItemizationEndMixpanel,
  trackItemizationStartMixpanel,
  trackItemizationUpdateMixpanel,
} from '../../../../../../utils/mixpanelUtils';
import utils from '../../../../utils/utils';
import CurrencyPrefix from '../../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../../InputContainer/InputContainer';
import EducationExpensesChart from '../../Charts/EducationExpensesChart/EducationExpensesChart';
import * as Styles from './EducationExpensesItemizeForm.module.scss';

export default function EducationExpensesItemizeForm(props: any) {
  const {
    handleItemizeTotals,
    handleItemizeChange,
    numberChildren,
    handleAddChildren,
    values,
    onBlur,
    onFocus,
    errors,
  } = props;

  const mixpanel = useMixpanel();
  //const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  React.useEffect(() => {
    trackItemizationStartMixpanel(STEP_LABELS.EDUCATION_EXPENSES, mixpanel);
    return () => {
      trackItemizationEndMixpanel(STEP_LABELS.EDUCATION_EXPENSES, mixpanel);
    };
  }, []);

  const handleTotal = () => {
    trackItemizationUpdateMixpanel(STEP_LABELS.EDUCATION_EXPENSES, mixpanel);
    let sum = itemizeUtils.getItemizedTotals('educationExpenses', values);
    handleItemizeTotals(
      STEP_NAMES.EDUCATION_EXPENSES,
      'educationExpenses',
      sum,
    );

    handleAddChildren(values);
  };

  const handleChildRows = () => {
    let arr = [];
    for (let i = 0; i < numberChildren; i++) {
      arr.push(0);
    }
    return arr.map((el, idx) => {
      return (
        <Box key={idx}>
          <Box
            className={`${Globals.FlexRow} ${Globals.ColumnGapNoOuterPadding}`}
          >
            <Box
              className={Globals.FlexColumn}
              style={{ paddingBottom: '24px' }}
            >
              <FormikFieldAdapter
                name={`child-${idx + 1}`}
                label={`Child ${idx + 1}`}
                renderField={(adapterProps) => (
                  <InputContainer style={{ marginBottom: '0px' }}>
                    <CurrencyPrefix />
                    <WLInput
                      {...adapterProps}
                      variant={SelectVariant.FLUSHED}
                      autoFocus={false}
                      onChange={handleItemizeChange}
                      onFocus={(e) =>
                        onFocus(e, STEP_LABELS.EDUCATION_EXPENSES)
                      }
                      onBlur={(e) => onBlur(e, STEP_LABELS.EDUCATION_EXPENSES)}
                    />
                  </InputContainer>
                )}
              />
            </Box>
          </Box>
        </Box>
      );
    });
  };

  const validateFields = () => {
    let errorsExist: boolean = false;
    Object.values(errors).map((el, idx) => {
      if (!utils.isEmpty(el) && el !== 'Required') {
        errorsExist = true;
      }
    });
    if (errorsExist) {
      return true;
    } else {
      return errorsExist;
    }
  };

  return (
    <>
      <section
        className={`${Globals.Section} ${Styles.Section} `}
        style={{ height: '100%' }}
      >
        <h2 className={`${Globals.Subheading} ${Globals.ParagraphLarge}`}>
          Help Me Calculate My Total Education Expenses
        </h2>
        <EducationExpensesChart />
        <hr className={Styles.HR} />
        <p
          className={`${Globals.Paragraph} ${Globals.ParagraphSmall}`}
          style={{ paddingTop: '24px' }}
        >
          Number of children: {numberChildren}
        </p>
        {handleChildRows()}
        <Box
          className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
        >
          <Box className={Globals.FlexColumn}>
            <Box className={Globals.Subtotal} style={{ marginBottom: '24px' }}>
              <strong>
                Total: $
                {utils.numFormat(
                  itemizeUtils.getItemizedTotals('educationExpenses', values),
                )}
              </strong>
            </Box>
          </Box>
        </Box>
        <div
          className={`${Globals.ButtonContainer} ${Globals.ButtonContainerFlex} ${Styles.ButtonContainer}`}
        >
          <WLButton
            type="button"
            className={Globals.ButtonBlue}
            aria-label="Update"
            onClick={handleTotal}
            isDisabled={validateFields()}
          >
            Update
          </WLButton>
        </div>
      </section>
    </>
  );
}
