import React from 'react';

import {
  ContactRep,
  CurrentAssets,
  Debt,
  EducationExpenses,
  FinalExpenses,
  GettingStarted,
  LivingExpenses,
  Mortgage,
  Thankyou
} from '../components/Steps/index';
import { STEP_NAMES } from '../store/constants/stepConstants';
import utils from './utils';

export const updateLivingExpenses = function (
  monthly: number,
  yearsNeeded: number
) {
  let livingExpenses;
  if (
    !utils.isEmpty(monthly) &&
    utils.isNumeric(monthly) &&
    !utils.isEmpty(yearsNeeded) &&
    utils.isNumeric(yearsNeeded)
  ) {
    // FV = PV * (1 + CPI) ** YRS
    // FV = Future Value
    // PV = Present Value
    // CPI = Consumer Price Index
    // YRS = Number of Years

    let fv = 0;
    let pv = monthly * 12; // Calculate annual living expenses.
    let cpi = 0.029;
    let yrs = yearsNeeded;
    for (let i = 1; i <= yrs; i++) {
      fv += pv * (1 + cpi) ** i;
    }
    livingExpenses = Math.round(fv);
  } else {
    livingExpenses = 0;
  }
  return livingExpenses;
};

export const displaySteps = function (
  steps: [{ name: string }],
  activeStep: number,
  totalIdx: number,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
) {
  switch (steps[activeStep].name) {
    case STEP_NAMES.GETTING_STARTED:
      return <GettingStarted onChange={onChange} />;
    case STEP_NAMES.FINAL_EXPENSES:
      return <FinalExpenses onChange={onChange} />;
    case STEP_NAMES.MORTGAGE:
      return <Mortgage onChange={onChange} />;
    case STEP_NAMES.DEBT:
      return <Debt onChange={onChange} />;
    case STEP_NAMES.EDUCATION_EXPENSES:
      return <EducationExpenses onChange={onChange} />;
    case STEP_NAMES.LIVING_EXPENSES:
      return <LivingExpenses onChange={onChange} />;
    case STEP_NAMES.CURRENT_ASSETS:
      return <CurrentAssets onChange={onChange} />;
    case STEP_NAMES.CONTACT_REP:
      return (
        <ContactRep onChange={onChange} totalIdx={totalIdx} steps={steps} />
      );
    case STEP_NAMES.THANKYOU:
      return <Thankyou onChange={onChange} />;
    default:
      break;
  }
};
