import React from 'react';

import o1458 from '../../images/o1458.jpg';
import o1459 from '../../images/o1459.jpg';
import o1460 from '../../images/o1460.jpg';
import o1461 from '../../images/o1461.jpg';
import o1462 from '../../images/o1462.jpg';
import o1465 from '../../images/o1465.jpg';
import { campaignImagesState } from '../../store/state/campaignImagesState';
import utils from '../../utils/utils';
import * as Styles from './Ad.module.scss';

interface AdProps {}

export default function Ad<AdProps>() {
  const [campaignImages, setCampaignImages] =
    React.useState(campaignImagesState);
  const [imageId, setImageId] = React.useState('');
  const [utmContent, setUTMContent] = React.useState('');

  React.useEffect(() => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      let utmContent = urlParams.get('utm_content') || '';
      setUTMContent(() => utmContent);
    }
  }, []);

  React.useEffect(() => {
    setImageId(getImageId());
  }, [utmContent]);

  const getImageId = () => {
    return !utils.isEmpty(utmContent) &&
      campaignImages.active.includes(utmContent.toLowerCase())
      ? utmContent
      : campaignImages.default;
  };
  const getImageSrc = () => {
    if (getImageId() === 'o1458') {
      return o1458;
    }
    if (getImageId() === 'o1459') {
      return o1459;
    }

    if (getImageId() === 'o1460') {
      return o1460;
    }

    if (getImageId() === 'o1461') {
      return o1461;
    }
    if (getImageId() === 'o1462') {
      return o1462;
    }
    if (getImageId() === 'o1465') {
      return o1465;
    }

    return o1462;
  };
  const getImageAlt = () => {
    let imageId = getImageId();
    let imageAlt;
    campaignImages.attr.map((el) => {
      if ((el.id = imageId)) {
        imageAlt = el.alt;
      }
    });
    return imageAlt || '';
  };
  return (
    <div className={Styles.Ad}>
      <header className={Styles.AdHeader}>
        <h2 className={Styles.AdHeading}>
          Get an idea of{' '}
          <strong>how much life insurance you might need </strong>
          in just a few minutes.
        </h2>
        <div className={Styles.AdBanner}>
          <div className={`${Styles.AdBannerCircle} ${Styles.Lines}`}>
            <div className={Styles.AdBannerCircleText}>
              <sup className={Styles.SuperScript}>$</sup>
              <span className={Styles.BigText}>35.85</span>
              <br />
              per month<sup>*</sup>
            </div>
          </div>
          <p className={Styles.AdBannerText}>
            <strong>$1 Million</strong> in coverage
            <br />
            can cost as little as...{' '}
          </p>
        </div>
      </header>
      <div className={Styles.AdBody}>
        <img
          className={Styles.AdImage}
          alt="Young couple standing together enjoying breakfast with their infant son who is seated on the counter"
          src={o1462.toString()}
        />
      </div>
      <div className={Styles.AdFooter}></div>
    </div>
  );
}
