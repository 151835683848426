import { Stack } from '@chakra-ui/react';
import React from 'react';

import * as Globals from '../../global.module.scss';
import * as Styles from './InputContainer.module.scss';

export default function InputContainer(props: any) {
  return (
    <div className={Styles.InputContainer} style={props.style}>
      <div
        className={Styles.InputWrapper}
        style={{
          maxWidth: props.maxWidth,
          paddingLeft: props.paddingLeft
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
