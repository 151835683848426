import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { WLButton, WLInput } from '../../../../../../design-library';
import { SelectVariant } from '../../../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter from '../../../../../Form/FormikFieldAdapter';
import * as Globals from '../../../../global.module.scss';
import {
  STEP_LABELS,
  STEP_NAMES,
} from '../../../../store/constants/stepConstants';
import itemizeUtils from '../../../../utils/itemizeUtils';
import {
  trackItemizationEndMixpanel,
  trackItemizationStartMixpanel,
  trackItemizationUpdateMixpanel,
} from '../../../../../../utils/mixpanelUtils';
import utils from '../../../../utils/utils';
import CurrencyPrefix from '../../../CurrencyPrefix/CurrencyPrefix';
import InputContainer from '../../../InputContainer/InputContainer';

interface DebtItemizeFormProps {
  handleItemizeTotals: (
    stepName: string,
    formName: string,
    sum: number | undefined,
  ) => {};
  handleItemizeChange: any;
  onBlur: any;
  onFocus: any;
  values: { [key: string]: string };
  errors: { [key: string]: string };
}

const DebtItemizeForm: React.FC<DebtItemizeFormProps> = ({
  handleItemizeTotals,
  handleItemizeChange,
  onBlur,
  onFocus,
  values,
  errors,
}) => {
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    trackItemizationStartMixpanel(STEP_LABELS.DEBT, mixpanel);
    return () => {
      trackItemizationEndMixpanel(STEP_LABELS.DEBT, mixpanel);
    };
  }, []);

  const handleTotal = () => {
    trackItemizationUpdateMixpanel(STEP_LABELS.DEBT, mixpanel);
    let sum = itemizeUtils.getItemizedTotals('debt', values);
    handleItemizeTotals(STEP_NAMES.DEBT, 'debt', sum);
  };

  const validateFields = () => {
    let invalid = false;
    for (const [key, value] of Object.entries(errors)) {
      switch (key) {
        case 'debtAutoLoans':
          if (!utils.isEmpty(values) && !utils.isNumeric(value)) {
            invalid = true;
            break;
          }
        case 'debtCreditCards':
          if (!utils.isEmpty(values) && !utils.isNumeric(value)) {
            invalid = true;
            break;
          }
        case 'debtPersonalLoans':
          if (!utils.isEmpty(values) && !utils.isNumeric(value)) {
            invalid = true;
            break;
          }
        case 'debtOther':
          if (!utils.isEmpty(values) && !utils.isNumeric(value)) {
            invalid = true;
            break;
          }
        default:
          break;
      }
    }
    return invalid;
  };

  return (
    <section className={Globals.Section}>
      <h2 className={`${Globals.ParagraphLarge} ${Globals.Subheading}`}>
        Help Me Calculate My Total Debt
      </h2>
      <p className={Globals.Paragraph}>Please provide your current balances.</p>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ paddingBottom: '24px' }}>
          <FormikFieldAdapter
            name="debtAutoLoans"
            label="Auto Loans"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onChange={handleItemizeChange}
                  onFocus={(e) => onFocus(e, STEP_LABELS.DEBT)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.DEBT)}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn} style={{ paddingBottom: '24px' }}>
          <FormikFieldAdapter
            name="debtCreditCards"
            label="Credit Cards"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onChange={handleItemizeChange}
                  onFocus={(e) => onFocus(e, STEP_LABELS.DEBT)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.DEBT)}
                />
              </InputContainer>
            )}
          />
        </div>
      </div>
      <div
        className={`${Globals.FlexRow} ${Globals.FlexRow050505} ${Globals.ColumnGapNoOuterPadding}`}
      >
        <div className={Globals.FlexColumn} style={{ paddingBottom: '24px' }}>
          <FormikFieldAdapter
            name="debtPersonalLoans"
            label="Personal Loans"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onChange={handleItemizeChange}
                  onFocus={(e) => onFocus(e, STEP_LABELS.DEBT)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.DEBT)}
                />
              </InputContainer>
            )}
          />
        </div>
        <div className={Globals.FlexColumn} style={{ paddingBottom: '24px' }}>
          <FormikFieldAdapter
            name="debtOther"
            label="Other"
            renderField={(adapterProps) => (
              <InputContainer style={{ marginBottom: '0' }}>
                <CurrencyPrefix />
                <WLInput
                  {...adapterProps}
                  variant={SelectVariant.FLUSHED}
                  autoFocus={false}
                  onChange={handleItemizeChange}
                  onFocus={(e) => onFocus(e, STEP_LABELS.DEBT)}
                  onBlur={(e) => onBlur(e, STEP_LABELS.DEBT)}
                />
              </InputContainer>
            )}
          />
        </div>
      </div>
      <div id="debtTotalDiv" className={Globals.Subtotal}>
        <strong>
          Total: $
          {utils.numFormat(itemizeUtils.getItemizedTotals('debt', values))}
        </strong>
      </div>
      <div
        className={`${Globals.ButtonContainer} ${Globals.ButtonContainerFlex}`}
      >
        <WLButton
          className={Globals.ButtonBlue}
          type="button"
          onClick={handleTotal}
          isDisabled={validateFields()}
        >
          Update
        </WLButton>
      </div>
    </section>
  );
};

export default DebtItemizeForm;
