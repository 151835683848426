import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import * as Globals from '../../../global.module.scss';
import { STEP_LABELS } from '../../../store/constants/stepConstants';
import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../../utils/mixpanelUtils';

export default function Thankyou(props: any) {
  const mixpanel = useMixpanel();

  React.useEffect(() => {
    const ts = Math.floor(Date.now() / 1000);
    trackStepStartMixpanel(STEP_LABELS.THANKYOU, mixpanel);
    return () => {
      trackStepEndMixpanel(STEP_LABELS.THANKYOU, mixpanel);
    };
  }, []);

  const onWholeLifeLinkClick = () => {
    mixpanel.track('Thank You - Whole Life Link');
  };

  const onTermLifeLinkClick = () => {
    mixpanel.track('Thank You - Term Life Link', {
      Clicked: true,
    });
  };

  const onUniversalLifeLinkClick = () => {
    mixpanel.track('Thank You - Universal Life Link', {
      Clicked: true,
    });
  };

  const styles = {
    brightBlue: '#0072ce',
  };

  return (
    <>
      <h2 className={`${Globals.Subheading} ${Globals.ParagraphLarge}`}>
        Thank You
      </h2>
      <p className={Globals.Paragraph}>
        Thank you for requesting information from WoodmenLife.{' '}
        {props.repData && props.repData.alias
          ? `${props.repData.alias} `
          : props.repData && props.repData.firstName
          ? `${props.repData.firstName} `
          : `Your local
        Representative `}
        will be contacting you soon.
      </p>
      <p className={Globals.Paragraph}>
        In the meantime, please take a look at the several types of life
        insurance WoodmenLife offers:
      </p>
      <ul
        style={{
          marginLeft: '.5rem',
          fontSize: '1rem',
          lineHeight: '1.75rem',
          listStyleType: 'circle',
        }}
      >
        <li>
          <a
            style={{ color: styles.brightBlue }}
            href="/insurance/life-insurance/whole-life/"
            target="_blank"
            onClick={onWholeLifeLinkClick}
          >
            Whole Life Insurance
          </a>
        </li>
        <li>
          <a
            style={{ color: styles.brightBlue }}
            href="/insurance/life-insurance/term-life/"
            target="_blank"
            onClick={onTermLifeLinkClick}
          >
            Term Life Insurance
          </a>
        </li>
        <li>
          <a
            style={{ color: styles.brightBlue }}
            href="/insurance/life-insurance/universal-life/"
            target="_blank"
            onClick={onUniversalLifeLinkClick}
          >
            Universal Life Insurance
          </a>
        </li>
      </ul>
    </>
  );
}
