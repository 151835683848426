import React from 'react';

import * as Styles from './StepMarker.module.scss';

const StepMarker = (props: { valid: boolean }) => {
  const { valid } = props;

  return (
    <div className={Styles.Step__Marker}>
      {valid ? (
        <svg
          version="1.1"
          baseProfile="tiny"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          xmlSpace="preserve"
        >
          <circle fill="#05B440" cx="8" cy="8" r="8" />
          <polyline
            fill="none"
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="4.7,8.5 
     6.7,10.8 11.3,5.2 "
          />
        </svg>
      ) : (
        <svg
          version="1.1"
          baseProfile="tiny"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          xmlSpace="preserve"
        >
          <circle fill="#FFD741" cx="8" cy="8" r="8" />
          <g>
            <line
              fill="none"
              stroke="#212121"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              x1="8"
              y1="4.4"
              x2="8"
              y2="9.2"
            />
            <circle fill="#212121" cx="8" cy="11.5" r="0.6" />
          </g>
        </svg>
      )}
    </div>
  );
};

export default StepMarker;
